import React from 'react';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import useUserStore from 'store/useUser';

import Card from 'components/Card';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

// Allow timestamps in the table to be relative (i.e., time from now).
dayjs.extend(relativeTime);

const Table = ({ templates }) => {
  return (
    <Card
      header={<div className="text-gray-700">Your existing templates</div>}
      body={
        <table className="table-fixed w-full">
          <TableHeader />
          <TableBody data={templates.map(row => row)} />
        </table>
      }
    />
  );
};

const Header = ({ className = '', ...props }) => (
  <th
    className={`py-3 px-1 mr-2 font-medium text-gray-900 text-sm text-left ${className}`}
    {...props}
  />
);

const Cell = ({ className = '', ...props }) => (
  <td className={`px-1 py-3 ${className}`} {...props} />
);

const TableHeader = () => {
  return (
    <thead className="">
      <tr>
        <Header className="w-48 pl-3">Name</Header>
        {/* FIXME Phase 2:
        <Header className="w-32">League</Header>
        */}
        <Header>Match</Header>
        <Header className="w-24">Updated</Header>
        <Header className="w-16"></Header>
      </tr>
    </thead>
  );
};

const TableBody = ({ data }) => {
  const user = useUserStore(state => state.user);

  return (
    <tbody className="">
      {data.map((row, i) => (
        <tr
          key={row.id}
          className={`text-sm text-gray-700 hover:bg-blue-200 cursor-default ${
            i % 2 && 'bg-gray-200'
          }`}
        >
          <Cell className="pl-3 text-gray-900 break-all">{row.name}</Cell>
          {/* FIXME Phase 2:
          <Cell className="text-gray-600">
            {row.league.name.replace(' League', '')}
          </Cell>
          */}
          <Cell className="">
            <div className="text-gray-800 bg-gray-500 px-2 py-1 rounded text-xs mr-2 inline-block text-center">
              {row.match.daytime}
            </div>
            {row.match.versus}
          </Cell>
          <Cell className="text-gray-600">
            {dayjs(row.updatedAt).fromNow()}
          </Cell>
          <td className="px-1">
            {user.id === row.owner && (
              <span className="w-full h-full flex items-center justify-center">
                <EditButton
                  id={row.id}
                  userId={user.id}
                  className="py-3 pl-1 pr-1"
                />
                <DeleteButton id={row.id} className="py-3 pr-1" />
              </span>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

Table.defaultProps = {
  tmeplates: []
};

export default Table;
