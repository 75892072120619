import React from 'react';
import Icon from './Icon';

const MemoArrowLeft = React.memo(props => (
  <Icon
    d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
    {...props}
  />
));

export default MemoArrowLeft;
