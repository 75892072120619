import create from 'zustand';
import * as dayjs from 'dayjs';

/**
 * This store controls the date used by the match picker. Note that the start
 * property is an integer that represents the number of days from today, where
 * 0 is today, 1 is tomorrow, -1 is yesterday, and so on.
 */

const initialState = {
  start: 0
};

export const [useDateStore, apiDateStore] = create((set, get) => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),

  forward: () => set(() => ({ start: get().start + 7 })),
  back: () => set(() => ({ start: get().start - 7 })),

  setStartByDate: newDate =>
    set(() => {
      const matchDay = dayjs(newDate).startOf('day');
      const offsetInDays = matchDay.diff(dayjs().startOf('day'), 'days');
      return { start: offsetInDays };
    })
}));

export default useDateStore;
