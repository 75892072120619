import React from 'react';

// FIXME Phase 2: import useSourceStore from 'store/useSource';
// FIXME Phase 2: import useLeagueStore from 'store/useLeague';
import useMatchStore from 'store/useMatch';
import useNameStore from 'store/useName';
import useFeaturesStore from 'store/useFeatures';

import Card from 'components/Card';
import Warning from 'components/Alert/Warning';

const Block = ({ children, label }) => (
  <div className="text-gray-900 text-base">
    <span className="font-semibold text-gray-500 mr-2 inline-block w-16">
      {label}
    </span>
    {children || <span className="text-gray-400">Not entered yet</span>}
  </div>
);

const Incomplete = () => (
  <Warning
    className="mb-4"
    text="Please complete all of your selections to publish"
  />
);

const Preview = ({ canSubmit }) => {
  const name = useNameStore(state => state.value);
  // FIXME Phase 2: const source = useSourceStore(state => state.selected);
  // FIXME Phase 2: const league = useLeagueStore(state => state.selected);
  const match = useMatchStore(state => state.selected);
  const features = useFeaturesStore(state => state.enabled);

  return (
    <Card
      title={<div className="px-6 pt-4">Preview</div>}
      body={
        <div className="px-6 pb-4">
          {!canSubmit && <Incomplete />}
          <Block label="Name">{name}</Block>
          {/* // FIXME Phase 2:
          <Block label="Source">{source?.name}</Block>
          <Block label="League">{league?.name}</Block>
          <Block label="Season">{match?.season.name}</Block>
          */}
          <Block label="Match">{match?.versus}</Block>
          <Block label="Time">{match?.daytime}</Block>
          <Block label="Features">
            {features.length &&
              features.map(o => (
                <span
                  key={o.value}
                  className="inline-block bg-gray-200 rounded-md px-3 py-1 text-sm font-medium text-gray-700 mb-2 mr-2"
                >
                  {o.label}
                </span>
              ))}
          </Block>
        </div>
      }
    />
  );
};

export default Preview;
