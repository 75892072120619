import React, { useCallback } from 'react';
import { useModal } from 'react-modal-hook';

import useTemplateRemove from 'hooks/useTemplateRemove';

import Icons from 'components/Icons';
import { Spinner } from 'components/Loading';
import Modal from 'components/Modal';

function DeleteButton({ className, id, ...props }) {
  const [remove, isRemoving] = useTemplateRemove();

  const [showModal, hideModal] = useModal(() => (
    <Modal
      title="Confirmation"
      body="Are you sure you want to delete this template?"
      onCancel={hideModal}
      onProceed={handleProceed}
      isOpen
    />
  ));

  const handleProceed = useCallback(() => {
    hideModal();
    remove(id);
  }, [id, hideModal, remove]);

  if (isRemoving) return <Spinner size={20} />;

  return (
    <button
      className={`text-gray-500 hover:text-red-600 focus:outline-none ${className}`}
      title="Delete template"
      onClick={showModal}
      {...props}
    >
      <Icons.Delete size={20} />
    </button>
  );
}

DeleteButton.defaultProps = {
  className: ''
};

export default React.memo(DeleteButton);
