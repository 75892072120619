/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listOptaCompetitions = /* GraphQL */ `
  query ListOptaCompetitions(
    $filter: ModelOptaCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaCompetitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOptaCompetition = /* GraphQL */ `
  query GetOptaCompetition($id: ID!) {
    getOptaCompetition(id: $id) {
      id
      name
      code
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOptaMatchs = /* GraphQL */ `
  query ListOptaMatchs(
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaMatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOptaMatch = /* GraphQL */ `
  query GetOptaMatch($id: ID!) {
    getOptaMatch(id: $id) {
      id
      type
      period
      date
      city
      venue
      homeTeamID
      awayTeamID
      competitionID
      seasonID
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      homeTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const matchesBySeasonAwayTeam = /* GraphQL */ `
  query MatchesBySeasonAwayTeam(
    $seasonID: ID
    $awayTeamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesBySeasonAwayTeam(
      seasonID: $seasonID
      awayTeamID: $awayTeamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const matchesBySeasonHomeTeam = /* GraphQL */ `
  query MatchesBySeasonHomeTeam(
    $seasonID: ID
    $homeTeamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesBySeasonHomeTeam(
      seasonID: $seasonID
      homeTeamID: $homeTeamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const matchesByTeams = /* GraphQL */ `
  query MatchesByTeams(
    $awayTeamID: ID
    $homeTeamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesByTeams(
      awayTeamID: $awayTeamID
      homeTeamID: $homeTeamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const matchesBySeason = /* GraphQL */ `
  query MatchesBySeason(
    $seasonID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesBySeason(
      seasonID: $seasonID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const matchesByCompetition = /* GraphQL */ `
  query MatchesByCompetition(
    $competitionID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    matchesByCompetition(
      competitionID: $competitionID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOptaMatchEvent = /* GraphQL */ `
  query GetOptaMatchEvent($id: ID!) {
    getOptaMatchEvent(id: $id) {
      competitionID
      seasonID
      matchID
      id
      type
      eventTime
      data
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listOptaMatchEvents = /* GraphQL */ `
  query ListOptaMatchEvents(
    $filter: ModelOptaMatchEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaMatchEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        competitionID
        seasonID
        matchID
        id
        type
        eventTime
        data
        createdAt
        updatedAt
        match {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventsBySeason = /* GraphQL */ `
  query EventsBySeason(
    $seasonID: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsBySeason(
      seasonID: $seasonID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competitionID
        seasonID
        matchID
        id
        type
        eventTime
        data
        createdAt
        updatedAt
        match {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventsByMatch = /* GraphQL */ `
  query EventsByMatch(
    $matchID: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByMatch(
      matchID: $matchID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competitionID
        seasonID
        matchID
        id
        type
        eventTime
        data
        createdAt
        updatedAt
        match {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOptaMatchLineup = /* GraphQL */ `
  query GetOptaMatchLineup($id: ID!) {
    getOptaMatchLineup(id: $id) {
      id
      matchID
      teamID
      playerID
      playerStatsID
      position
      subPosition
      order
      status
      subOn
      subOff
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      player {
        id
        firstName
        lastName
        height
        weight
        dob
        country
        createdAt
        updatedAt
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const listOptaMatchLineups = /* GraphQL */ `
  query ListOptaMatchLineups(
    $filter: ModelOptaMatchLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaMatchLineups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchID
        teamID
        playerID
        playerStatsID
        position
        subPosition
        order
        status
        subOn
        subOff
        createdAt
        updatedAt
        match {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        player {
          id
          firstName
          lastName
          height
          weight
          dob
          country
          createdAt
          updatedAt
        }
        stats {
          id
          totalYelCard
          wonTackle
          gamesPlayed
          totalRedCards
          fkFoulLost
          passingAccuracy
          totalPass
          fkFoulWon
          goalConversion
          totalTackle
          totalOffside
          goals
          totalScoringAtt
          ontargetScoringAtt
          goalAssists
          headedGoals
          hitWoodwork
          offsides
          ownGoalsConceded
          penaltiesConceded
          penaltiesTaken
          penaltyGoals
          setPiecesGoals
          starts
          substituteOff
          substituteOn
          shirtNumber
          createdAt
          updatedAt
        }
        team {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const lineupsByMatch = /* GraphQL */ `
  query LineupsByMatch(
    $matchID: ID
    $teamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaMatchLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineupsByMatch(
      matchID: $matchID
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        matchID
        teamID
        playerID
        playerStatsID
        position
        subPosition
        order
        status
        subOn
        subOff
        createdAt
        updatedAt
        match {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        player {
          id
          firstName
          lastName
          height
          weight
          dob
          country
          createdAt
          updatedAt
        }
        stats {
          id
          totalYelCard
          wonTackle
          gamesPlayed
          totalRedCards
          fkFoulLost
          passingAccuracy
          totalPass
          fkFoulWon
          goalConversion
          totalTackle
          totalOffside
          goals
          totalScoringAtt
          ontargetScoringAtt
          goalAssists
          headedGoals
          hitWoodwork
          offsides
          ownGoalsConceded
          penaltiesConceded
          penaltiesTaken
          penaltyGoals
          setPiecesGoals
          starts
          substituteOff
          substituteOn
          shirtNumber
          createdAt
          updatedAt
        }
        team {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listOptaPlayers = /* GraphQL */ `
  query ListOptaPlayers(
    $filter: ModelOptaPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        height
        weight
        dob
        country
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOptaPlayer = /* GraphQL */ `
  query GetOptaPlayer($id: ID!) {
    getOptaPlayer(id: $id) {
      id
      firstName
      lastName
      height
      weight
      dob
      country
      createdAt
      updatedAt
    }
  }
`;
export const listOptaSeasons = /* GraphQL */ `
  query ListOptaSeasons(
    $filter: ModelOptaSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaSeasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOptaSeason = /* GraphQL */ `
  query GetOptaSeason($id: ID!) {
    getOptaSeason(id: $id) {
      id
      name
      seasonID
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      matches {
        items {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const seasonsByCompetition = /* GraphQL */ `
  query SeasonsByCompetition(
    $competitionID: ID
    $seasonID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seasonsByCompetition(
      competitionID: $competitionID
      seasonID: $seasonID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOptaSeasonStanding = /* GraphQL */ `
  query GetOptaSeasonStanding($id: ID!) {
    getOptaSeasonStanding(id: $id) {
      id
      competitionID
      seasonID
      teamID
      teamStatsID
      matchDay
      against
      awayAgainst
      awayDrawn
      awayFor
      awayLost
      awayPlayed
      awayPoints
      awayPosition
      awayWon
      drawn
      for
      homeAgainst
      homeDrawn
      homeFor
      homeLost
      homePlayed
      homePoints
      homePosition
      homeWon
      lost
      played
      currentOutcome
      points
      position
      startDayPosition
      won
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const listOptaSeasonStandings = /* GraphQL */ `
  query ListOptaSeasonStandings(
    $filter: ModelOptaSeasonStandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaSeasonStandings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        competitionID
        seasonID
        teamID
        teamStatsID
        matchDay
        against
        awayAgainst
        awayDrawn
        awayFor
        awayLost
        awayPlayed
        awayPoints
        awayPosition
        awayWon
        drawn
        for
        homeAgainst
        homeDrawn
        homeFor
        homeLost
        homePlayed
        homePoints
        homePosition
        homeWon
        lost
        played
        currentOutcome
        points
        position
        startDayPosition
        won
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        stats {
          id
          totalYelCard
          wonTackle
          gamesPlayed
          totalRedCards
          fkFoulLost
          passingAccuracy
          totalPass
          fkFoulWon
          goalConversion
          totalTackle
          totalOffside
          goals
          totalScoringAtt
          ontargetScoringAtt
          goalAssists
          headedGoals
          hitWoodwork
          offsides
          ownGoalsConceded
          penaltiesConceded
          penaltiesTaken
          penaltyGoals
          setPiecesGoals
          starts
          substituteOff
          substituteOn
          shirtNumber
          createdAt
          updatedAt
        }
        team {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const standingsBySeason = /* GraphQL */ `
  query StandingsBySeason(
    $seasonID: ID
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaSeasonStandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    standingsBySeason(
      seasonID: $seasonID
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        competitionID
        seasonID
        teamID
        teamStatsID
        matchDay
        against
        awayAgainst
        awayDrawn
        awayFor
        awayLost
        awayPlayed
        awayPoints
        awayPosition
        awayWon
        drawn
        for
        homeAgainst
        homeDrawn
        homeFor
        homeLost
        homePlayed
        homePoints
        homePosition
        homeWon
        lost
        played
        currentOutcome
        points
        position
        startDayPosition
        won
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        stats {
          id
          totalYelCard
          wonTackle
          gamesPlayed
          totalRedCards
          fkFoulLost
          passingAccuracy
          totalPass
          fkFoulWon
          goalConversion
          totalTackle
          totalOffside
          goals
          totalScoringAtt
          ontargetScoringAtt
          goalAssists
          headedGoals
          hitWoodwork
          offsides
          ownGoalsConceded
          penaltiesConceded
          penaltiesTaken
          penaltyGoals
          setPiecesGoals
          starts
          substituteOff
          substituteOn
          shirtNumber
          createdAt
          updatedAt
        }
        team {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const standingsBySeasonTeam = /* GraphQL */ `
  query StandingsBySeasonTeam(
    $seasonID: ID
    $teamID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOptaSeasonStandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    standingsBySeasonTeam(
      seasonID: $seasonID
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        competitionID
        seasonID
        teamID
        teamStatsID
        matchDay
        against
        awayAgainst
        awayDrawn
        awayFor
        awayLost
        awayPlayed
        awayPoints
        awayPosition
        awayWon
        drawn
        for
        homeAgainst
        homeDrawn
        homeFor
        homeLost
        homePlayed
        homePoints
        homePosition
        homeWon
        lost
        played
        currentOutcome
        points
        position
        startDayPosition
        won
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        stats {
          id
          totalYelCard
          wonTackle
          gamesPlayed
          totalRedCards
          fkFoulLost
          passingAccuracy
          totalPass
          fkFoulWon
          goalConversion
          totalTackle
          totalOffside
          goals
          totalScoringAtt
          ontargetScoringAtt
          goalAssists
          headedGoals
          hitWoodwork
          offsides
          ownGoalsConceded
          penaltiesConceded
          penaltiesTaken
          penaltyGoals
          setPiecesGoals
          starts
          substituteOff
          substituteOn
          shirtNumber
          createdAt
          updatedAt
        }
        team {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listOptaStatss = /* GraphQL */ `
  query ListOptaStatss(
    $filter: ModelOptaStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOptaStats = /* GraphQL */ `
  query GetOptaStats($id: ID!) {
    getOptaStats(id: $id) {
      id
      totalYelCard
      wonTackle
      gamesPlayed
      totalRedCards
      fkFoulLost
      passingAccuracy
      totalPass
      fkFoulWon
      goalConversion
      totalTackle
      totalOffside
      goals
      totalScoringAtt
      ontargetScoringAtt
      goalAssists
      headedGoals
      hitWoodwork
      offsides
      ownGoalsConceded
      penaltiesConceded
      penaltiesTaken
      penaltyGoals
      setPiecesGoals
      starts
      substituteOff
      substituteOn
      shirtNumber
      createdAt
      updatedAt
    }
  }
`;
export const listOptaTeams = /* GraphQL */ `
  query ListOptaTeams(
    $filter: ModelOptaTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptaTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOptaTeam = /* GraphQL */ `
  query GetOptaTeam($id: ID!) {
    getOptaTeam(id: $id) {
      id
      name
      country
      abbreviation
      createdAt
      updatedAt
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
      data
      leagueID
      seasonID
      matchID
      environment
      source
      optaLeague {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      optaMatch {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      optaSeason {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      srLeague {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      srSeason {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
      srMatch {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        data
        leagueID
        seasonID
        matchID
        environment
        source
        optaLeague {
          id
          name
          code
          createdAt
          updatedAt
        }
        optaMatch {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        optaSeason {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        srLeague {
          id
          name
          createdAt
          updatedAt
        }
        srSeason {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        srMatch {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const templatesByEnvironment = /* GraphQL */ `
  query TemplatesByEnvironment(
    $environment: Environment
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByEnvironment(
      environment: $environment
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        data
        leagueID
        seasonID
        matchID
        environment
        source
        optaLeague {
          id
          name
          code
          createdAt
          updatedAt
        }
        optaMatch {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        optaSeason {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        srLeague {
          id
          name
          createdAt
          updatedAt
        }
        srSeason {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        srMatch {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSrCategorys = /* GraphQL */ `
  query ListSrCategorys(
    $filter: ModelSRCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        countryCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSrCategory = /* GraphQL */ `
  query GetSrCategory($id: ID!) {
    getSRCategory(id: $id) {
      id
      name
      countryCode
      createdAt
      updatedAt
    }
  }
`;
export const listSrCompetitions = /* GraphQL */ `
  query ListSrCompetitions(
    $filter: ModelSRCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSrCompetition = /* GraphQL */ `
  query GetSrCompetition($id: ID!) {
    getSRCompetition(id: $id) {
      id
      name
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSrCompetitors = /* GraphQL */ `
  query ListSrCompetitors(
    $filter: ModelSRCompetitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRCompetitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSrCompetitor = /* GraphQL */ `
  query GetSrCompetitor($id: ID!) {
    getSRCompetitor(id: $id) {
      id
      name
      country
      countryCode
      abbreviation
      gender
      createdAt
      updatedAt
      players {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getSrCompetitorPlayer = /* GraphQL */ `
  query GetSrCompetitorPlayer($id: ID!) {
    getSRCompetitorPlayer(id: $id) {
      id
      competitorID
      playerID
      jerseyNumber
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const listSrCompetitorPlayers = /* GraphQL */ `
  query ListSrCompetitorPlayers(
    $filter: ModelSRCompetitorPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRCompetitorPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        competitorID
        playerID
        jerseyNumber
        createdAt
        updatedAt
        competitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        player {
          id
          name
          type
          DOB
          nationality
          countryCode
          height
          weight
          placeOfBirth
          gender
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSrPlayers = /* GraphQL */ `
  query ListSrPlayers(
    $filter: ModelSRPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSrPlayer = /* GraphQL */ `
  query GetSrPlayer($id: ID!) {
    getSRPlayer(id: $id) {
      id
      name
      type
      DOB
      nationality
      countryCode
      height
      weight
      placeOfBirth
      gender
      createdAt
      updatedAt
      competitors {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getSrPlayerStatistics = /* GraphQL */ `
  query GetSrPlayerStatistics($id: ID!) {
    getSRPlayerStatistics(id: $id) {
      id
      seasonID
      competitorID
      playerID
      assists
      cardsGiven
      cornerKicks
      goalsByHead
      goalsByPenalty
      goalsScored
      matchesPlayed
      minutesPlayed
      offsides
      ownGoals
      redCards
      shotsBlocked
      shotsOffTarget
      shotsOnTarget
      substitutedIn
      substitutedOut
      yellowCards
      createdAt
      updatedAt
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const listSrPlayerStatisticss = /* GraphQL */ `
  query ListSrPlayerStatisticss(
    $filter: ModelSRPlayerStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRPlayerStatisticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        seasonID
        competitorID
        playerID
        assists
        cardsGiven
        cornerKicks
        goalsByHead
        goalsByPenalty
        goalsScored
        matchesPlayed
        minutesPlayed
        offsides
        ownGoals
        redCards
        shotsBlocked
        shotsOffTarget
        shotsOnTarget
        substitutedIn
        substitutedOut
        yellowCards
        createdAt
        updatedAt
        player {
          id
          name
          type
          DOB
          nationality
          countryCode
          height
          weight
          placeOfBirth
          gender
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSrPushEvent = /* GraphQL */ `
  query GetSrPushEvent($id: ID!) {
    getSRPushEvent(id: $id) {
      competitionID
      seasonID
      sportEventID
      id
      type
      eventTime
      matchClock
      matchTime
      team
      competitor
      outcome
      injuryTimeAnnounced
      periodName
      homeScore
      awayScore
      players
      player
      createdAt
      updatedAt
    }
  }
`;
export const listSrPushEvents = /* GraphQL */ `
  query ListSrPushEvents(
    $filter: ModelSRPushEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRPushEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        competitionID
        seasonID
        sportEventID
        id
        type
        eventTime
        matchClock
        matchTime
        team
        competitor
        outcome
        injuryTimeAnnounced
        periodName
        homeScore
        awayScore
        players
        player
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pushEventsBySeason = /* GraphQL */ `
  query PushEventsBySeason(
    $seasonID: ID
    $eventTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSRPushEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pushEventsBySeason(
      seasonID: $seasonID
      eventTime: $eventTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competitionID
        seasonID
        sportEventID
        id
        type
        eventTime
        matchClock
        matchTime
        team
        competitor
        outcome
        injuryTimeAnnounced
        periodName
        homeScore
        awayScore
        players
        player
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pushEventsBySportEvent = /* GraphQL */ `
  query PushEventsBySportEvent(
    $sportEventID: ID
    $eventTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSRPushEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pushEventsBySportEvent(
      sportEventID: $sportEventID
      eventTime: $eventTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competitionID
        seasonID
        sportEventID
        id
        type
        eventTime
        matchClock
        matchTime
        team
        competitor
        outcome
        injuryTimeAnnounced
        periodName
        homeScore
        awayScore
        players
        player
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSrSeasons = /* GraphQL */ `
  query ListSrSeasons(
    $filter: ModelSRSeasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRSeasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSrSeason = /* GraphQL */ `
  query GetSrSeason($id: ID!) {
    getSRSeason(id: $id) {
      id
      name
      startDate
      endDate
      year
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      sportEvents {
        items {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getSrSeasonStanding = /* GraphQL */ `
  query GetSrSeasonStanding($id: ID!) {
    getSRSeasonStanding(id: $id) {
      id
      seasonID
      competitorID
      type
      rank
      played
      win
      loss
      draw
      goalDiff
      points
      change
      currentOutcome
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const listSrSeasonStandings = /* GraphQL */ `
  query ListSrSeasonStandings(
    $filter: ModelSRSeasonStandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRSeasonStandings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        seasonID
        competitorID
        type
        rank
        played
        win
        loss
        draw
        goalDiff
        points
        change
        currentOutcome
        createdAt
        updatedAt
        competitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSrSportEvents = /* GraphQL */ `
  query ListSrSportEvents(
    $filter: ModelSRSportEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRSportEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSrSportEvent = /* GraphQL */ `
  query GetSrSportEvent($id: ID!) {
    getSRSportEvent(id: $id) {
      id
      startTime
      startTimeConfirmed
      homeCompetitorID
      awayCompetitorID
      seasonID
      categoryID
      status
      matchStatus
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      category {
        id
        name
        countryCode
        createdAt
        updatedAt
      }
      homeCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      awayCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const sportEventsBySeason = /* GraphQL */ `
  query SportEventsBySeason(
    $seasonID: ID
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSRSportEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sportEventsBySeason(
      seasonID: $seasonID
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSrSportEventLineup = /* GraphQL */ `
  query GetSrSportEventLineup($id: ID!) {
    getSRSportEventLineup(id: $id) {
      id
      sportEventID
      competitorID
      playerID
      jerseyNumber
      starter
      played
      order
      position
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
      sportEvent {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSrSportEventLineups = /* GraphQL */ `
  query ListSrSportEventLineups(
    $filter: ModelSRSportEventLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRSportEventLineups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sportEventID
        competitorID
        playerID
        jerseyNumber
        starter
        played
        order
        position
        createdAt
        updatedAt
        competitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        player {
          id
          name
          type
          DOB
          nationality
          countryCode
          height
          weight
          placeOfBirth
          gender
          createdAt
          updatedAt
        }
        sportEvent {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const lineupsBySportEvent = /* GraphQL */ `
  query LineupsBySportEvent(
    $sportEventID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSRSportEventLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineupsBySportEvent(
      sportEventID: $sportEventID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sportEventID
        competitorID
        playerID
        jerseyNumber
        starter
        played
        order
        position
        createdAt
        updatedAt
        competitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        player {
          id
          name
          type
          DOB
          nationality
          countryCode
          height
          weight
          placeOfBirth
          gender
          createdAt
          updatedAt
        }
        sportEvent {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
