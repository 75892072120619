import React, { useEffect, useRef, useState } from 'react';

import useTabsStore from 'store/useTabs';

const getVisibility = (i, cur) => {
  if (i > cur) return 'translate-x-full opacity-0';
  if (i < cur) return '-translate-x-full opacity-0';
  return 'translate-x-0 opacity-1';
};

const TabState = {
  IDLE: 'idle',
  ENTERING: 'entering',
  EXITING: 'exiting',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const TabContent = ({ children, index }) => {
  const current = useTabsStore(state => state.selected);
  const transitionRef = useRef(null);
  const [state, setState] = useState(TabState.IDLE);
  const visibility = getVisibility(index, current);
  useEffect(() => {
    setState(state => {
      switch(state) {
        case TabState.IDLE: return current === index ?  TabState.ACTIVE : TabState.IDLE;
        case TabState.ACTIVE: return current === index ? TabState.ACTIVE : TabState.EXITING;
        case TabState.INACTIVE: return current === index ? TabState.ENTERING : TabState.INACTIVE;
        default: return state;
      }
    })
  }, [index, current])

  const onTransitionEnd = (evt) => {
    if (evt.target !== transitionRef.current) return;
    setState(state => {
      switch(state) {
        case TabState.ENTERING: return TabState.ACTIVE;
        case TabState.EXITING: return TabState.INACTIVE;
        default:
          return state
      }
    });
  }

  const containerStyle = {
    pointerEvents: state === TabState.ACTIVE ? 'auto' : 'none',
    overflowY: state === TabState.ACTIVE ? 'auto' : 'hidden'
  }

  return (
    <div
      className="absolute top-0 bottom-0 mt-16 py-4 w-full max-w-4xl overflow-x-hidden bg-transparent"
      style={containerStyle}
    >
      <div
        ref={transitionRef}
        className={`relative z-10 transform translate transition duration-200 ${visibility}`}
        onTransitionEnd={onTransitionEnd}
      >
        <div className="pt-4">{children}</div>
      </div>
    </div>
  );
};

export default TabContent;
