import { useCallback, useEffect, useState } from 'react';

import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import Amplify from '@aws-amplify/core';
import AWS from 'aws-sdk';

import {
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_AUTH_TYPE,
  AWS_STORAGE_BUCKET,
  AWS_STORAGE_LEVEL,
  CONFIG
} from 'utils/constants';

import useUserStore from 'store/useUser';

function useAuthParams() {
  const [idToken, setIdToken] = useState();
  const [identityId, setIdentityId] = useState();
  const [tokenType, setTokenType] = useState();

  useEffect(() => {
    window.location.hash
      .replace(/^#/, '')
      .split('&')
      .map(param => param.split('='))
      .forEach(param => {
        if (param[0] === 'id_token') setIdToken(param[1]);
        if (param[0] === 'IdentityId') setIdentityId(param[1]);
        if (param[0] === 'token_type') setTokenType(param[1]);
      });
  }, [setIdToken, setIdentityId, setTokenType]);

  // NOTE: `tokenType` doesn't seem to be used.
  useEffect(() => {
    if (idToken) setTokenType(tokenType || 'OpenId');
  }, [idToken, tokenType]);

  return [idToken, identityId, tokenType];
}

// Function To asynchronously authenticate user from SSO credentials.
function authenticateIdentity(idToken, identityId, tokenType) {
  return new Promise(function (resolve, reject) {
    localStorage.setItem('cognitoUserPoolId', idToken);
    localStorage.setItem('IdentityId', identityId);
    localStorage.setItem('TokenType', tokenType);

    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: AWS_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: { 'cognito-identity.amazonaws.com': idToken }
      },
      { region: AWS_REGION }
    );

    AWS.config.credentials.get(function (err, data) {
      if (err) return reject(err);
      resolve(data);
    });
  });
}

function initStorage() {
  Storage.configure({
    bucket: AWS_STORAGE_BUCKET,
    level: AWS_STORAGE_LEVEL,
    region: AWS_REGION,
    identityPoolId: AWS_IDENTITY_POOL_ID
  });
}

function useAuth() {
  const [idToken, identityId, tokenType] = useAuthParams();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const user = useUserStore(state => state.user);
  const setUser = useUserStore(state => state.setUser);

  const initAmplify = useCallback(() => {
    const credentials = () => Auth.currentCredentials();
    const config = { ...CONFIG };
    config.auth = { type: AWS_AUTH_TYPE, credentials };

    API.configure(config);
    Amplify.configure(config);

    setAuthenticated(true);
    setUser(AWS.config.credentials.data);
  }, [setAuthenticated, setUser]);

  useEffect(() => {
    async function initAWS() {
      try {
        await authenticateIdentity(idToken, identityId, tokenType);
        initAmplify();
        initStorage();
      } catch (error) {
        setAuthenticated(false);
        setUser(null);
        setError(error);
      }
    }
    if (idToken && identityId) initAWS();
  }, [idToken, identityId, tokenType, initAmplify, setError, setUser]);

  return [user, isAuthenticated, error];
}

export default useAuth;
