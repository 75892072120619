import useErrorStore from 'store/useError';
// FIXME Phase 2: import useSourceStore from 'store/useSource';
import useDateStore from 'store/useDates';
// FIXME Phase 2: import useLeagueStore from 'store/useLeague';
import useMatchStore from 'store/useMatch';
import useNameStore from 'store/useName';
import usePanelSponsorStore from 'store/usePanelSponsor';
import useTickerSponsorStore from 'store/useTickerSponsor';
import useFeaturesStore from 'store/useFeatures';
import useTemplateIdStore from 'store/useTemplateId';

function useReset() {
  const resetErrorMessage = useErrorStore(state => state.reset);
  // FIXME Phase 2: const resetLeague = useLeagueStore(state => state.reset);
  const resetMatch = useMatchStore(state => state.reset);
  const resetName = useNameStore(state => state.reset);
  // FIXME Phase 2: const resetSource = useSourceStore(state => state.reset);
  const resetFeatures = useFeaturesStore(state => state.reset);
  const resetSponsorPanel = usePanelSponsorStore(state => state.reset);
  const resetSponsorTicker = useTickerSponsorStore(state => state.reset);
  const resetTemplateId = useTemplateIdStore(state => state.reset);
  const resetDates = useDateStore(state => state.reset);

  async function resetStores() {
    resetErrorMessage();
    resetDates();
    // FIXME Phase 2: resetSource();
    resetName();
    resetFeatures();
    resetSponsorPanel();
    resetSponsorTicker();
    resetTemplateId();
    // FIXME Phase 2: resetLeague();
    resetMatch();
  }

  return [resetStores];
}

export default useReset;
