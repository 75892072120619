import React from 'react';
import { BarLoader, ClipLoader } from 'react-spinners';

import theme from 'utils/theme';

const color = theme.colors.pink[500];

export const Loader = () => (
  <div className="absolute top-0 left-0 w-screen h-screen z-50">
    <BarLoader color={color} height={3} width="100%" />
    <div className="absolute right-0 top-0 m-8">
      <ClipLoader size={50} color={color} />
    </div>
  </div>
);

const Loading = props => (
  <div className="h-screen w-screen flex justify-center items-center text-gray-500">
    {props.animate && <Loader />}
    <h1>{props.children}</h1>
  </div>
);

export const Spinner = ({ className, color, size }) => (
  <div className={`flex self-center ${className}`}>
    <ClipLoader size={size} color={color} />
  </div>
);

Spinner.defaultProps = {
  className: '',
  color: theme.colors.pink[600],
  size: 20
};

export default Loading;
