import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const Cancel = ({ className, ...props }) => (
  <Button
    className={`text-gray-700 hover:text-gray-900 ${className}`}
    label="Cancel"
    {...props}
  />
);

Cancel.defaultProps = {
  label: 'Cancel'
};

Cancel.propTypes = {
  label: PropTypes.string
};

export default React.memo(Cancel);
