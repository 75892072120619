import React, { useCallback } from 'react';

import useEdit from 'hooks/useEdit';

import Icons from 'components/Icons';
import { Spinner } from 'components/Loading';

function EditButton({ className, id, userId, ...props }) {
  const [load, isLoading] = useEdit(userId);
  const handleClick = useCallback(() => load(id), [id, load]);

  if (isLoading) return <Spinner size={20} className={className} />;

  return (
    <button
      className={`text-gray-500 hover:text-orange-500 focus:outline-none ${className}`}
      title="Edit template"
      onClick={handleClick}
      {...props}
    >
      <Icons.Edit size={20} />
    </button>
  );
}

EditButton.defaultProps = {
  className: ''
};

export default React.memo(EditButton);
