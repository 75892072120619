import { DEFAULT_SPONSOR_URL } from 'utils/constants';

const makeSponsorState = defaultImage => {
  const initialState = {
    image: defaultImage,
    link: DEFAULT_SPONSOR_URL,
  };

  return (set, get) => ({
    ...initialState,
    reset: () => set(() => ({ ...initialState })),
    getImage: () => get().image,
    setImage: newImage => set(() => ({ image: newImage })),
    getLink: () => get().link,
    setLink: newLink => set(() => ({ link: newLink }))
  });
};

export default makeSponsorState;
