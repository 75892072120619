import create from 'zustand';

import { DEFAULT_SPONSOR_PANEL_IMAGE } from 'utils/constants';

import makeSponsorState from './makeSponsorState';

const panelSponsorState = makeSponsorState(DEFAULT_SPONSOR_PANEL_IMAGE);

export const [usePanelSponsorStore] = create(panelSponsorState);

export default usePanelSponsorStore;
