import useFeaturesStore from './useFeatures';
import useLeagueStore from './useLeague';
import useMatchStore from './useMatch';
import useNameStore from './useName';
import useSourceStore from './useSource';
import usePanelSponsorStore from './usePanelSponsor';
import shallow from 'zustand/shallow';
import useTickerSponsorStore from './useTickerSponsor';
import resetTemplateId from './useTemplateId';
import { ENV as environment } from '../utils/constants';

const sponsorState = ({ image, link }) => ({ image, link });

export default function () {
  const features = useFeaturesStore(state => state.enabled);
  const league = useLeagueStore(state => state.selected);
  const match = useMatchStore(state => state.selected);
  const name = useNameStore(state => state.value);
  const source = useSourceStore(state => state.selected);
  const sponsorPanel = usePanelSponsorStore(sponsorState, shallow);
  const sponsorTicker = useTickerSponsorStore(sponsorState, shallow);
  const templateId = resetTemplateId(state => state.value);

  const getTemplate = () => {
    const data = JSON.stringify({
      widgets: features.map(o => o.value),
      sponsorPanel,
      sponsorTicker
    });
    const payload = {
      leagueID: league.id,
      matchID: match.id,
      seasonID: match.season.id,
      source: source.id,
      data,
      environment,
      name
    };

    // Supports updating templates.
    if (templateId) {
      payload.id = templateId;
    }

    console.debug('Submitting', { payload });
    return payload;
  }

  return [getTemplate];
}
