import { queryTransform } from 'utils/query';

import { apiSource } from 'store/useSource';

import log from './log';

const initialState = {
  error: undefined,
  isLoading: false,
  list: [],
  selected: undefined,
  resetCount: 0
};

const dropdownState = log((set, get) => ({
  ...initialState,
  // FIXME Phase 2: reset: () => set(() => ({ ...initialState })),
  reset: () =>
    set(({ list, resetCount }) => ({
      ...initialState,
      resetCount: resetCount + 1,
      list
    })),

  setError: newError => set(() => ({ error: newError })),
  setIsLoading: newIsLoading => set(() => ({ isLoading: newIsLoading })),
  setList: newList => set(() => ({ error: undefined, list: newList || [] })),
  setSelected: newId => set(() => ({ selected: newId })),

  fetch: async (options, sourceId) => {
    const { setError, setIsLoading, setList } = get();
    setIsLoading(true);
    try {
      const source = apiSource.getState().selected || {};
      const queryArgs = options[sourceId || source.id];
      if (queryArgs) {
        const newList = await queryTransform(...queryArgs);
        setList(newList);
      }
    } catch (error) {
      console.error(error);
      setError('We had a problem loading data');
    }
    setIsLoading(false);
  }
}));

export default dropdownState;
