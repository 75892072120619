/**
 * Export full Tailwind theme for JS usage.
 *
 * https://tailwindcss.com/docs/configuration/#referencing-in-javascript
 */

//
// TODO: Would normally import the actual config, but CRA restricts imports
// outside of the `src` folder.
//
// import resolveConfig from 'tailwindcss/resolveConfig';
// import tailwindConfig from 'tailwind.config.js';
// const fullConfig = resolveConfig(tailwindConfig);

const defaultTheme = require('tailwindcss/defaultTheme');

export default defaultTheme;
