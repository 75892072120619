import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';

import useDateStore from 'store/useDates';
import useMatchStore, { makeOptions } from 'store/useMatch';
import group from 'utils/group';

import ArrowLeft from 'components/Icons/ArrowLeft';
import ArrowRight from 'components/Icons/ArrowRight';
import { Spinner } from 'components/Loading';
import useLeagueStore from 'store/useLeague';

const Loading = () => (
  <div className="py-2">
    <Text>
      <Spinner size={24} />
    </Text>
  </div>
);

const TabButton = ({ active, enable, select, ...props }) => {
  const a = active && 'border-b border-black';
  const b = enable
    ? select
      ? 'text-green-600 font-semibold border-green-600'
      : 'text-black'
    : 'text-gray-600 hover:text-gray-800 border-transparent';

  return (
    <div
      className={`w-32 p-2 cursor-pointer border-transparent border-b ${a} ${b}`}
      {...props}
    />
  );
};

const TabContent = ({ active, away, home, time, ...props }) => (
  <div
    className={`flex items-center text-center py-2 cursor-pointer hover:bg-gray-200 transition duration-100 ${
      active ? 'font-medium bg-green-400 hover:bg-green-400' : 'text-gray-800'
    }`}
    {...props}
  >
    <span className="w-5/12 text-sm text-right">{home}</span>
    <span className="w-2/12">
      <span className="text-gray-800 text-xs font-normal bg-gray-500 py-1 px-2 rounded">
        {time}
      </span>
    </span>
    <span className="w-5/12 text-sm text-left">{away}</span>
  </div>
);

const Text = props => (
  <div className="p-4 mx-auto text-gray-500 text-center" {...props} />
);

const TabArrow = ({ children, onClick }) => {
  return (
    <span
      className="px-2 text-gray-400 hover:text-gray-900 cursor-pointer"
      onClick={onClick}
    >
      {children}
    </span>
  );
};

const Match = () => {
  const fetch = useMatchStore(state => state.fetch);
  const isLoading = useMatchStore(state => state.isLoading);
  const list = useMatchStore(state => state.list);
  const resetCount = useMatchStore(state => state.resetCount);
  const setList = useMatchStore(state => state.setList);
  const selected = useMatchStore(state => state.selected);
  const setSelected = useMatchStore(state => state.setSelected);

  const league = useLeagueStore(state => state.selected);

  const startDate = useDateStore(state => state.start);
  const forward = useDateStore(state => state.forward);
  const back = useDateStore(state => state.back);

  const [dateTab, setDateTab] = useState(0);

  const selectedId = selected && selected.id;

  // Creates array of dates from three days before to three days after.
  const matchesByDay = group(list, 'day');
  const days = [...Array(7).keys()].map(i => {
    const label = dayjs()
      .add(startDate + i, 'days')
      .format('ddd MMM DD');
    const options = matchesByDay[label];
    return { label, options };
  });

  const content = days.map(({ label, options }, i) => (
    <React.Fragment key={label}>
      {options
        ? options.map(o =>
            dateTab === i ? (
              <TabContent
                key={`tab-content-${o.id}`}
                active={selectedId === o.id}
                home={o.home}
                away={o.away}
                time={`${o.time} ${o.timezone}`}
                onClick={() =>
                  selectedId === o.id ? setSelected() : setSelected(o)
                }
              />
            ) : null
          )
        : dateTab === i && (
            <div className={`flex items-center text-center py-2`}>
              <Text>There are no matches on {label}</Text>
            </div>
          )}
    </React.Fragment>
  ));

  const tabs = days.map(({ label, options = [] }, i) => (
    <TabButton
      key={`tab-${label}`}
      active={dateTab === i}
      enable={options.length}
      select={options.filter(o => o.id === (selected || {}).id).length}
      onClick={() => setDateTab(i)}
    >
      <div>{label.substr(0, 3)}</div>
      <div>{label.substr(4).replace(/ 0/, ' ')}</div>
    </TabButton>
  ));

  useEffect(() => {
    let asyncCanceled = false;
    async function updateList() {
      const options = makeOptions(league.id, startDate);
      await fetch(options);
      if (asyncCanceled) return;
      setDateTab(0);
    }
    league && updateList();
    return () => (asyncCanceled = true);
  }, [league, startDate, fetch, setList, setDateTab]);

  useEffect(() => {
    setDateTab(0);
  }, [resetCount]);

  return (
    <div>
      <div className="flex flex-row items-center text-center text-sm">
        <TabArrow onClick={() => back()}>
          <ArrowLeft />
        </TabArrow>
        {tabs}
        <TabArrow onClick={() => forward()}>
          <ArrowRight />
        </TabArrow>
      </div>
      <div className="flex justify-center items-center overflow-y-scroll bg-gray-100 py-2">
        {isLoading && <Loading />}
        {!isLoading && <div className="w-full">{content}</div>}
      </div>
    </div>
  );
};

export default Match;
