import React, { useCallback, useState, useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import useReset from 'hooks/useReset';
import useTemplateCreate from 'hooks/useTemplateCreate';
import useTemplateLoad from 'hooks/useTemplateLoad';
import useTemplateUpdate from 'hooks/useTemplateUpdate';

import useTabsStore from 'store/useTabs';
import useTemplatesStore from 'store/useTemplates';

import Error from 'components/Error';
import Form from 'components/Form';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import Preview from 'components/Preview';
import Table from 'components/Table';
import Tabs from 'components/Tabs';

import Button from 'components/Button/Button';
import CancelButton from 'components/Button/Cancel';
import PublishButton from 'components/Button/Publish';
import { Spinner } from 'components/Loading';
import useTemplate from 'store/useTemplate';

const AppLoading = () => <Loading animate>Loading your templates...</Loading>;
const AuthLoading = () => <Loading animate>Logging you in...</Loading>;
const AuthError = () => (
  <Loading>
    <p>There was an issue loading the template editor.</p>
    <p>Please close this window and try again.</p>
  </Loading>
);
const NoTemplates = ({ onClick }) => (
  <div className="flex justify-center bg-gray-200 rounded py-4">
    You have no templates saved.
    <button
      className="mx-1 underline text-blue-500 hover:text-blue-600 focus:outline-none"
      onClick={onClick}
    >
      Create a new template
    </button>
    to get started.
  </div>
);

const App = () => {

  const [user, isAuthenticated, authError] = useAuth();
  const [create, isCreating] = useTemplateCreate();
  const [loadTemplates, isLoading, loadError] = useTemplateLoad();
  const [update, isUpdating] = useTemplateUpdate();
  const [resetStores] = useReset();

  const tab = useTabsStore(state => state.selected);
  const setTab = useTabsStore(state => state.setSelected);

  const templates = useTemplatesStore(state => state.list);

  const [canSubmit, setCanSubmit] = useState(false);
  const [error, setError] = useState();

  const handleChange = useCallback(done => setCanSubmit(done && tab === 2), [
    setCanSubmit,
    tab
  ]);

  const handleError = useCallback(error => setError(error), [setError]);
  
  const handleClickCancel = useCallback(async () => {
    setTab(0);
    await resetStores();
  }, [resetStores, setTab]);

  const [getTemplate] = useTemplate();
  // Call the submit method within the form.
  const handleClickPublish = useCallback(
    async () => {
      const payload = getTemplate();
      try {
        const template = { ...payload, owner: user?.id };
        if (template.id) {
          await update(template);
        } else {
          await create(template);
        }
        setTab(0);
        await resetStores();
      } catch (error) {
        console.error(error);
        setError('There was an issue publishing');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.id, canSubmit]
  );

  const handleClickCreate = useCallback(async () => {
    await resetStores();
    setTab(1);
  }, [resetStores, setTab]);

  useEffect(() => {
    user?.id && loadTemplates(user?.id);
    // If we include loadTemplates as a dependency, this loops infinitely. There
    // are suggestions for resolving this, but they don't seem compatible with
    // using a function defined in a hook.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  if (!user && isAuthenticated) return <AuthError />;
  if (!user && authError) return <AuthError />;
  if (!user && loadError) return <AuthError />;
  if (!user) return <AuthLoading />;
  if (isLoading) return <AppLoading />;

  return (
    <div className="flex flex-col h-screen text-gray-900 antialiased bg-white">
      {/* Floating alert */}
      <div className="fixed top-0 right-0 w-108 h-16 z-50 mt-20 mr-6">
        <Error error={error} />
      </div>

      {/* Header */}
      <div className="w-full h-16 bg-white text-gray-800 z-10 flex flex-col border-b border-gray-400 px-4">
        <div className="flex items-center justify-between w-full h-full max-w-4xl mx-auto">
          {/* Left */}
          <div className="w-1/2">
            <div className="flex justify-start items-center">
              <Tabs.Links />
            </div>
          </div>
          {/* Right */}
          <div className="w-1/2">
            {/* Buttons */}
            <div className="flex justify-end">
              <CancelButton
                className="mr-2"
                label="Cancel"
                onClick={handleClickCancel}
              />
              {tab === 0 && (
                <Button
                  className="bg-blue-600 hover:bg-blue-700"
                  label="Create"
                  onClick={handleClickCreate}
                />
              )}
              {tab === 1 && (
                <Button
                  className="bg-blue-600 hover:bg-blue-700"
                  label="Preview"
                  onClick={() => setTab(2)}
                />
              )}
              {tab === 2 && (
                <PublishButton
                  enabled={canSubmit && !isCreating && !isUpdating}
                  busy={isCreating || isUpdating}
                  onClick={handleClickPublish}
                >
                  {isCreating || isUpdating ? (
                    <Spinner color="white" />
                  ) : (
                    'Publish'
                  )}
                </PublishButton>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1">
        <div className="flex h-full justify-center bg-white max-w-4xl mx-auto">
          <Tabs.Content index={0}>
            {templates.length ? (
              <Table templates={templates} />
            ) : (
              <NoTemplates onClick={handleClickCreate} />
            )}
          </Tabs.Content>

          <Tabs.Content index={1}>
            <Form
              onChange={handleChange}
              onError={handleError}
            />
          </Tabs.Content>

          <Tabs.Content index={2}>
            <Preview canSubmit={canSubmit} />
          </Tabs.Content>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default App;
