import { useEffect, useState, useCallback } from 'react';

import { getTemplate } from 'graphql/queries';

import useErrorStore from 'store/useError';
// FIXME Phase 2: import useSourceStore from 'store/useSource';
// FIXME Phase 2: import useLeagueStore from 'store/useLeague';
import useDateStore from 'store/useDates';
import useMatchStore from 'store/useMatch';
import useNameStore from 'store/useName';
import usePanelSponsorStore from 'store/usePanelSponsor';
import useTabs from 'store/useTabs';
import useTickerSponsorStore from 'store/useTickerSponsor';
import useFeaturesStore from 'store/useFeatures';
import useTemplateIdStore from 'store/useTemplateId';

import query from 'utils/query';

import { transformTemplate } from 'utils/transforms';

function useEdit(user) {
  const [isLoading, setIsLoading] = useState(false);
  const matchError = useMatchStore(state => state.error);

  const setErrorMessage = useErrorStore(state => state.setMessage);
  const setStartDate = useDateStore(state => state.setStartByDate);
  // FIXME Phase 2: const setLeague = useLeagueStore(state => state.setSelected);
  const setMatch = useMatchStore(state => state.setSelected);
  const setName = useNameStore(state => state.setValue);
  // FIXME Phase 2: const setSource = useSourceStore(state => state.setSelected);
  const setTab = useTabs(state => state.setSelected);
  const setFeatures = useFeaturesStore(state => state.setEnabled);
  const setSponsorPanelImage = usePanelSponsorStore(state => state.setImage);
  const setSponsorPanelLink = usePanelSponsorStore(state => state.setLink);
  const setSponsorTickerImage = useTickerSponsorStore(state => state.setImage);
  const setSponsorTickerLink = useTickerSponsorStore(state => state.setLink);
  const setTemplateId = useTemplateIdStore(state => state.setValue);

  const handleError = useCallback(
    error => {
      setIsLoading(false);
      setErrorMessage("We're having trouble loading your template");
      console.error('Template loading error', error);
    },
    [setIsLoading, setErrorMessage]
  );

  useEffect(() => {
    if (matchError) handleError(matchError);
  }, [matchError, handleError]);

  async function load(id) {
    setIsLoading(true);
    try {
      const config = {
        id: id
      };
      const { data } = await query(getTemplate, config);
      const template = transformTemplate(data.getTemplate);

      // Apparent race condition when setting match and date close together.
      // Placing date here seems to alleviate loading an empty match calendar.
      setStartDate(template.match.date);

      setTemplateId(template.id);
      setName(template.name);
      // FIXME Phase 2: setSource(template.source);
      setSponsorPanelImage(template.data.sponsorPanel.image);
      setSponsorPanelLink(template.data.sponsorPanel.link);
      setSponsorTickerImage(template.data.sponsorTicker.image);
      setSponsorTickerLink(template.data.sponsorTicker.link);
      setFeatures(template.data.widgets);
      // FIXME Phase 2: setLeague(template.optaLeague || template.srLeague);
      setMatch(template.match);

      // Small delay to allow form loading to finish before switching tabs.
      setTimeout(() => {
        setTab(1);
        setIsLoading(false);
      }, 300);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  }

  return [load, isLoading];
}

export default useEdit;
