import React from 'react';
import Select from 'react-select';

import theme from 'utils/theme';

// Unpacking theme object here to fit styles below on one line.
const { boxShadow, borderRadius, colors, cursor, fontSize, spacing } = theme;

// Theme the select dropdown to appear more like Tailwind.
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? colors.gray[200] : colors.white,
    borderColor: colors.gray[300],
    borderRadius: borderRadius.default,
    boxShadow:
      state.isFocused && !state.isDisabled ? boxShadow.outline : boxShadow.none,
    cursor: cursor.pointer,
    '&:hover': { boxShadow: boxShadow.outline }
  }),
  // Hide indicator
  indicatorSeparator: () => null,
  loadingIndicator: provided => ({
    ...provided,
    color: colors.pink[600]
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: colors.gray[200]
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: colors.gray[700],
    fontSize: fontSize.md,
    padding: spacing[1],
    paddingLeft: spacing[3],
    paddingRight: spacing[2]
  }),
  multiValueRemove: provided => ({
    ...provided,
    backgroundColor: colors.gray[200],
    color: colors.gray[600],
    fontSize: fontSize.lg,
    paddingLeft: spacing[2],
    paddingRight: spacing[2]
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.gray[500]
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? colors.gray[500] : colors.gray[800]
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: state.hasValue ? colors.gray[100] : colors.gray[100],
    padding: spacing[1],
    paddingLeft: spacing[2]
  })
};

// Wraps react-select for a better search filter. Matches all words searched
// anywhere in <option>.label, case-insensitive.
const searchFilter = (option, input) => {
  const words = input.split(' ');
  const label = option && option.label.toLowerCase();
  return words.reduce(
    (acc, cur) => acc && label.includes(cur.toLowerCase()),
    true
  );
};

export default props => (
  <Select filterOption={searchFilter} styles={customStyles} {...props} />
);
