import create from 'zustand';

import { FEATURES } from 'utils/constants';

const initialState = {
  list: [...FEATURES],
  enabled: []
};

export const [useFeaturesStore] = create((set, get) => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setEnabled: newList =>
    set(() => ({
      enabled: get().list.filter(o => (newList || []).includes(o.value))
    }))
}));

export default useFeaturesStore;
