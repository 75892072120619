import React, { useState } from 'react';

import useErrorStore from 'store/useError';
import usePanelSponsorStore from 'store/usePanelSponsor';
import useTickerSponsorStore from 'store/useTickerSponsor';

import FileUpload from 'components/Form/FileUpload';

export const Sponsor = ({ previewHeight, previewWidth, useStore }) => {
  const getImage = useStore(state => state.getImage);
  const getLink = useStore(state => state.getLink);
  const setLink = useStore(state => state.setLink);
  const setImage = useStore(state => state.setImage);

  const image = getImage();
  const link = getLink();

  const setErrorMessage = useErrorStore(state => state.setMessage);

  return (
    <div>
      <div className="mt-1 flex">
        <div className="flex items-center ml-1 text-gray-600 text-sm w-40">
          Image upload
        </div>
        <div className="w-full">
          <FileUpload onComplete={setImage} onError={setErrorMessage} />
        </div>
      </div>

      <div className="mt-4 flex">
        <div className="flex items-center ml-1 text-gray-600 text-sm w-40">
          On-click link
        </div>
        <label className="block w-full">
          <input
            className="form-input w-full"
            onChange={e => setLink(e.target.value)}
            placeholder="Open this link when user clicks image"
            type="text"
            value={link}
          />
        </label>
      </div>

      <div className="mt-3 flex">
        <div className="flex items-center ml-1 text-gray-600 text-sm w-40">
          Image preview
        </div>
        <div className="w-full">
          <div className="border-2 border-gray-200 inline-block">
            <img
              alt="Sponsor preview"
              style={{ width: previewWidth, height: previewHeight }}
              src={image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Panel = () => (
  <Sponsor
    previewHeight={46}
    previewWidth={356}
    useStore={usePanelSponsorStore}
  />
);

export const Ticker = () => (
  <Sponsor
    previewHeight={30}
    previewWidth={54}
    useStore={useTickerSponsorStore}
  />
);

const TabItem = ({ label, onClick, selected }) => (
  <li className="-mb-px mr-1 cursor-pointer" onClick={onClick}>
    <span
      className={`bg-white inline-block rounded-t py-2 px-4 font-normal text-sm border-b ${
        selected
          ? 'text-black border-b-2 border-black'
          : 'text-gray-500 hover:text-gray-800'
      }`}
      role="tab"
    >
      {label}
    </span>
  </li>
);

export const Component = () => {
  const [tab, setTab] = useState(1);

  return (
    <>
      <ul className="flex border-b" role="tablist">
        <TabItem label="Panel" selected={tab === 1} onClick={() => setTab(1)} />
        <TabItem
          label="Ticker"
          selected={tab === 2}
          onClick={() => setTab(2)}
        />
      </ul>
      <div className="p-4 pt-7 border-l border-b border-r">
        {tab === 1 && <Panel />}
        {tab === 2 && <Ticker />}
      </div>
    </>
  );
};

export default Component;
