import React, { useCallback, useEffect, useState } from 'react';

const Error = ({ error }) => {
  const [open, setOpen] = useState(false);

  const handleClickClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    if (error) setOpen(true);
  }, [setOpen, error]);

  return (
    <div
      className={`bg-red-100 border-2 border-red-300 text-red-700 shadow-xl px-4 py-3 rounded flex duration-1000 ease-in-out transform transition-transform ${
        open ? 'translate-x-0' : 'translate-x-9999'
      }`}
      role="alert"
    >
      <div className="flex-1">
        <strong className="font-bold mr-1">There was an issue</strong>
        <span className="block sm:inline font-normal">{error}</span>
      </div>
      <span
        className="w-12 p-3 -mt-3 -mr-3 cursor-pointer"
        onClick={handleClickClose}
      >
        <svg
          className="fill-current h-6 w-6 text-red-500 hover:text-red-600"
          viewBox="0 0 20 20"
        >
          <path d="M14.348 14.849a1.2 1.2 0 01-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 11-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 111.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 111.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 010 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export default Error;
