import create from 'zustand';

import { listOptaCompetitions } from 'graphql/queries';
import { listSrCompetitionsIncludeCategory } from 'graphql/custom/queries';
import { SR_ENABLE_LEAGUE_FILTER } from 'utils/constants';
import { transformLeague } from 'utils/transforms';

// FIXME Phase 2: import dropdownState from './dropdownState';

const transformOpta = ({ data }) =>
  data.listOptaCompetitions.items.map(({ id, name }) => ({ id, name }));

const transformSr = result =>
  result.data.listSRCompetitions.items
    .map(transformLeague)
    // TODO: Sort by country not working.
    .sort((a, b) => (a.name - b.name ? 1 : -1));

// NOTE: Only filter by the three league lists as defined at;
// https://prometheantv.atlassian.net/wiki/spaces/PRO/pages/204865629/Sport+Radar+IDs
const or = [
  { id: { eq: 'sr:competition:196' } },
  { id: { eq: 'sr:competition:17' } },
  { id: { eq: 'sr:competition:1032' } }
];
const filter = SR_ENABLE_LEAGUE_FILTER ? { or } : undefined;
const cfg = { limit: 1000, filter };

export const options = {
  SPORT_RADAR: [[listSrCompetitionsIncludeCategory, cfg], transformSr],
  OPTA: [[listOptaCompetitions, cfg], transformOpta]
};

// FIXME Phase 2: export const [useLeagueStore, apiLeagueStore] = create(dropdownState);

const ENGLISH_PREMIER_LEAGUE = { id: '8', name: 'English Premier League' };
export const [useLeagueStore, apiLeagueStore] = create(() => ({
  reset: () => ({ selected: ENGLISH_PREMIER_LEAGUE }),
  selected: ENGLISH_PREMIER_LEAGUE
}));

export default useLeagueStore;
