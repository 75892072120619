import React, { useState, useCallback, useEffect } from 'react';

import Dropzone from 'react-dropzone';

import useStorage from 'hooks/useStorage';

const FileUpload = ({ onComplete, onError }) => {
  const { error, isUploading, success, upload } = useStorage();
  const [isDragActive, setIsDragActive] = useState(false);

  const handleFileChange = useCallback(files => files.length && upload(files), [
    upload
  ]);

  useEffect(() => {
    if (error) onError(error);
  }, [error, onError]);

  useEffect(() => {
    if (success) onComplete(success);
  }, [success, onComplete]);

  return (
    <Dropzone
      multiple={false}
      accept="image/*" // Restrict to images ONLY
      onDrop={handleFileChange}
      onDragEnter={() => setIsDragActive(true)}
      onDragLeave={() => setIsDragActive(false)}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={`p-4 border-2 border-dashed rounded focus:bg-blue-200 focus:border-blue-600 focus:outline-none cursor-pointer ${
            isDragActive
              ? 'bg-blue-100 border-blue-500'
              : 'bg-gray-200 border-gray-300'
          }`}
        >
          <input {...getInputProps()} className="hover:bg-red-100" />
          <p className="text-center text-gray-600">
            {isUploading
              ? 'Uploading...'
              : 'Drop file or click to upload image'}
          </p>
        </div>
      )}
    </Dropzone>
  );
};

export default FileUpload;
