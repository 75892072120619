import React from 'react';
import PropTypes from 'prop-types';

function Publish({ busy, className, children, enabled, ...props }) {
  return (
    <button
      type="submit"
      className={`flex justify-center bg-green-600 hover:bg-green-700 text-white text-sm py-2 px-4 rounded focus:outline-none ${
        !enabled && 'opacity-75 cursor-not-allowed'
      } ${className}`}
      title="Publish template"
      {...props}
    >
      {children}
    </button>
  );
}

Publish.defaultProps = {
  className: '',
  enabled: false,
  label: 'Publish'
};

Publish.propTypes = {
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  label: PropTypes.string
};

export default React.memo(Publish);
