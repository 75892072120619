import create from 'zustand';

const initialState = {
  value: undefined
};

export const [useTemplateIdStore] = create(set => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setValue: newValue => set(() => ({ value: newValue }))
}));

export default useTemplateIdStore;
