import { useState } from 'react';

import { updateTemplate } from 'graphql/mutations';

import useTemplatesStore from 'store/useTemplates';
import { queryTransform } from 'utils/query';
import { transformTemplateList } from 'utils/transforms';

function useTemplateUpdate() {
  const templates = useTemplatesStore(state => state.list);
  const setTemplates = useTemplatesStore(state => state.setList);

  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState();

  async function update(template) {
    setIsUpdating(true);
    try {
      const newTemplates = await queryTransform(
        [updateTemplate, { input: template }],
        ({ data }) =>
          transformTemplateList([
            data.updateTemplate,
            ...templates.filter(o => o.id !== data.updateTemplate.id)
          ])
      );
      setTemplates(newTemplates);
      setIsUpdating(false);
    } catch (error) {
      console.error('Template update error', error);
      setError("We're having trouble updating your template");
    }
  }

  return [update, isUpdating, error];
}

export default useTemplateUpdate;
