export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_AUTH_TYPE = process.env.REACT_APP_AWS_AUTH_TYPE;

export const AWS_STORAGE_ROOT = process.env.REACT_APP_AWS_STORAGE_ROOT;
export const AWS_STORAGE_BUCKET = process.env.REACT_APP_AWS_STORAGE_BUCKET;
export const AWS_STORAGE_LEVEL = 'public';
export const AWS_STORAGE_FOLDER = 'footballstats';

export const DEFAULT_SPONSOR_TICKER_IMAGE =
  AWS_STORAGE_ROOT + 'default-sponsor-ticker';
export const DEFAULT_SPONSOR_PANEL_IMAGE =
  AWS_STORAGE_ROOT + 'default-sponsor-panel.jpg';
export const DEFAULT_SPONSOR_URL = 'https://promethean.tv/';

export const TEMPLATE_LIMIT = 20;
export const ENV = process.env.REACT_APP_ENV.toUpperCase();

export const FEATURES = [
  // { value: 'stats-score', label: 'Match Scores', category: 'ticker' },
  { value: 'stats-goal', label: 'Scored Goals', category: 'ticker' },
  { value: 'stats-fixtures', label: 'Fixtures', category: 'panel' },
  { value: 'stats-h2h', label: 'Head To Head', category: 'panel' },
  { value: 'stats-table', label: 'League Table', category: 'panel' },
  // { value: 'stats-live-table', label: 'Live League Table', category: 'panel' },
  { value: 'stats-lineup', label: 'Team Lineups', category: 'panel' }
];
export const DATE_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};

// Supported data sources for the stats overlays. This matches the amplify DataSource enum.
export const DATA_SOURCES = [
  { id: 'OPTA', name: 'Opta Sports' },
  { id: 'SPORT_RADAR', name: 'Sportradar' }
];

export const SR_ENABLE_LEAGUE_FILTER = false;

// AWS CONFIG that will be used to configure different AWS Amplify SDK. Originally, this was being loaded from aws-exports.js.
export const CONFIG = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_AUTH_TYPE
};

export const TABS = ['Select', 'Edit', 'Preview'];
