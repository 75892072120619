import create from 'zustand';
import * as dayjs from 'dayjs';

import {
  listSrSportEvents,
  optaMatchesByCompetition
} from 'graphql/custom/queries';

import { transformMatch } from 'utils/transforms';

import dropdownState from './dropdownState';

const transformOpta = ({ items }) =>
  items.map(transformMatch).sort((a, b) => a.sortValue - b.sortValue);

const transformSr = ({ items }, leagueId) =>
  items
    .filter(o => o.season.competitionID === leagueId && o)
    .map(transformMatch)
    .sort((a, b) => a.sortValue - b.sortValue);

export const makeOptions = (id, start, limit = 10000) => {
  const today = dayjs().startOf('day');
  const date = {
    between: [today.add(start, 'days'), today.add(start + 7, 'days')]
  };

  if (!id) return;

  return {
    SPORT_RADAR: [
      [listSrSportEvents, { filter: { startTime: date }, limit }],
      result => transformSr(result.data.listSRSportEvents, id)
    ],
    OPTA: [
      [optaMatchesByCompetition, { competitionID: id, date }],
      result => transformOpta(result.data.matches)
    ]
  };
};

export const [useMatchStore, apiMatchStore] = create(dropdownState);

export default useMatchStore;
