import create from 'zustand';

import { DEFAULT_SPONSOR_TICKER_IMAGE } from 'utils/constants';

import makeSponsorState from './makeSponsorState';

const tickerSponsorState = makeSponsorState(DEFAULT_SPONSOR_TICKER_IMAGE);

export const [useTickerSponsorStore] = create(tickerSponsorState);

export default useTickerSponsorStore;