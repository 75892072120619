/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOptaCompetition = /* GraphQL */ `
  mutation CreateOptaCompetition(
    $input: CreateOptaCompetitionInput!
    $condition: ModelOptaCompetitionConditionInput
  ) {
    createOptaCompetition(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOptaCompetition = /* GraphQL */ `
  mutation UpdateOptaCompetition(
    $input: UpdateOptaCompetitionInput!
    $condition: ModelOptaCompetitionConditionInput
  ) {
    updateOptaCompetition(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOptaCompetition = /* GraphQL */ `
  mutation DeleteOptaCompetition(
    $input: DeleteOptaCompetitionInput!
    $condition: ModelOptaCompetitionConditionInput
  ) {
    deleteOptaCompetition(input: $input, condition: $condition) {
      id
      name
      code
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOptaMatch = /* GraphQL */ `
  mutation CreateOptaMatch(
    $input: CreateOptaMatchInput!
    $condition: ModelOptaMatchConditionInput
  ) {
    createOptaMatch(input: $input, condition: $condition) {
      id
      type
      period
      date
      city
      venue
      homeTeamID
      awayTeamID
      competitionID
      seasonID
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      homeTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateOptaMatch = /* GraphQL */ `
  mutation UpdateOptaMatch(
    $input: UpdateOptaMatchInput!
    $condition: ModelOptaMatchConditionInput
  ) {
    updateOptaMatch(input: $input, condition: $condition) {
      id
      type
      period
      date
      city
      venue
      homeTeamID
      awayTeamID
      competitionID
      seasonID
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      homeTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteOptaMatch = /* GraphQL */ `
  mutation DeleteOptaMatch(
    $input: DeleteOptaMatchInput!
    $condition: ModelOptaMatchConditionInput
  ) {
    deleteOptaMatch(input: $input, condition: $condition) {
      id
      type
      period
      date
      city
      venue
      homeTeamID
      awayTeamID
      competitionID
      seasonID
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      homeTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
      awayTeam {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOptaMatchEvent = /* GraphQL */ `
  mutation CreateOptaMatchEvent(
    $input: CreateOptaMatchEventInput!
    $condition: ModelOptaMatchEventConditionInput
  ) {
    createOptaMatchEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      matchID
      id
      type
      eventTime
      data
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateOptaMatchEvent = /* GraphQL */ `
  mutation UpdateOptaMatchEvent(
    $input: UpdateOptaMatchEventInput!
    $condition: ModelOptaMatchEventConditionInput
  ) {
    updateOptaMatchEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      matchID
      id
      type
      eventTime
      data
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteOptaMatchEvent = /* GraphQL */ `
  mutation DeleteOptaMatchEvent(
    $input: DeleteOptaMatchEventInput!
    $condition: ModelOptaMatchEventConditionInput
  ) {
    deleteOptaMatchEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      matchID
      id
      type
      eventTime
      data
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createOptaMatchLineup = /* GraphQL */ `
  mutation CreateOptaMatchLineup(
    $input: CreateOptaMatchLineupInput!
    $condition: ModelOptaMatchLineupConditionInput
  ) {
    createOptaMatchLineup(input: $input, condition: $condition) {
      id
      matchID
      teamID
      playerID
      playerStatsID
      position
      subPosition
      order
      status
      subOn
      subOff
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      player {
        id
        firstName
        lastName
        height
        weight
        dob
        country
        createdAt
        updatedAt
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateOptaMatchLineup = /* GraphQL */ `
  mutation UpdateOptaMatchLineup(
    $input: UpdateOptaMatchLineupInput!
    $condition: ModelOptaMatchLineupConditionInput
  ) {
    updateOptaMatchLineup(input: $input, condition: $condition) {
      id
      matchID
      teamID
      playerID
      playerStatsID
      position
      subPosition
      order
      status
      subOn
      subOff
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      player {
        id
        firstName
        lastName
        height
        weight
        dob
        country
        createdAt
        updatedAt
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteOptaMatchLineup = /* GraphQL */ `
  mutation DeleteOptaMatchLineup(
    $input: DeleteOptaMatchLineupInput!
    $condition: ModelOptaMatchLineupConditionInput
  ) {
    deleteOptaMatchLineup(input: $input, condition: $condition) {
      id
      matchID
      teamID
      playerID
      playerStatsID
      position
      subPosition
      order
      status
      subOn
      subOff
      createdAt
      updatedAt
      match {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      player {
        id
        firstName
        lastName
        height
        weight
        dob
        country
        createdAt
        updatedAt
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOptaPlayer = /* GraphQL */ `
  mutation CreateOptaPlayer(
    $input: CreateOptaPlayerInput!
    $condition: ModelOptaPlayerConditionInput
  ) {
    createOptaPlayer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      height
      weight
      dob
      country
      createdAt
      updatedAt
    }
  }
`;
export const updateOptaPlayer = /* GraphQL */ `
  mutation UpdateOptaPlayer(
    $input: UpdateOptaPlayerInput!
    $condition: ModelOptaPlayerConditionInput
  ) {
    updateOptaPlayer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      height
      weight
      dob
      country
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptaPlayer = /* GraphQL */ `
  mutation DeleteOptaPlayer(
    $input: DeleteOptaPlayerInput!
    $condition: ModelOptaPlayerConditionInput
  ) {
    deleteOptaPlayer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      height
      weight
      dob
      country
      createdAt
      updatedAt
    }
  }
`;
export const createOptaSeason = /* GraphQL */ `
  mutation CreateOptaSeason(
    $input: CreateOptaSeasonInput!
    $condition: ModelOptaSeasonConditionInput
  ) {
    createOptaSeason(input: $input, condition: $condition) {
      id
      name
      seasonID
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      matches {
        items {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOptaSeason = /* GraphQL */ `
  mutation UpdateOptaSeason(
    $input: UpdateOptaSeasonInput!
    $condition: ModelOptaSeasonConditionInput
  ) {
    updateOptaSeason(input: $input, condition: $condition) {
      id
      name
      seasonID
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      matches {
        items {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOptaSeason = /* GraphQL */ `
  mutation DeleteOptaSeason(
    $input: DeleteOptaSeasonInput!
    $condition: ModelOptaSeasonConditionInput
  ) {
    deleteOptaSeason(input: $input, condition: $condition) {
      id
      name
      seasonID
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      matches {
        items {
          id
          type
          period
          date
          city
          venue
          homeTeamID
          awayTeamID
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOptaSeasonStanding = /* GraphQL */ `
  mutation CreateOptaSeasonStanding(
    $input: CreateOptaSeasonStandingInput!
    $condition: ModelOptaSeasonStandingConditionInput
  ) {
    createOptaSeasonStanding(input: $input, condition: $condition) {
      id
      competitionID
      seasonID
      teamID
      teamStatsID
      matchDay
      against
      awayAgainst
      awayDrawn
      awayFor
      awayLost
      awayPlayed
      awayPoints
      awayPosition
      awayWon
      drawn
      for
      homeAgainst
      homeDrawn
      homeFor
      homeLost
      homePlayed
      homePoints
      homePosition
      homeWon
      lost
      played
      currentOutcome
      points
      position
      startDayPosition
      won
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateOptaSeasonStanding = /* GraphQL */ `
  mutation UpdateOptaSeasonStanding(
    $input: UpdateOptaSeasonStandingInput!
    $condition: ModelOptaSeasonStandingConditionInput
  ) {
    updateOptaSeasonStanding(input: $input, condition: $condition) {
      id
      competitionID
      seasonID
      teamID
      teamStatsID
      matchDay
      against
      awayAgainst
      awayDrawn
      awayFor
      awayLost
      awayPlayed
      awayPoints
      awayPosition
      awayWon
      drawn
      for
      homeAgainst
      homeDrawn
      homeFor
      homeLost
      homePlayed
      homePoints
      homePosition
      homeWon
      lost
      played
      currentOutcome
      points
      position
      startDayPosition
      won
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteOptaSeasonStanding = /* GraphQL */ `
  mutation DeleteOptaSeasonStanding(
    $input: DeleteOptaSeasonStandingInput!
    $condition: ModelOptaSeasonStandingConditionInput
  ) {
    deleteOptaSeasonStanding(input: $input, condition: $condition) {
      id
      competitionID
      seasonID
      teamID
      teamStatsID
      matchDay
      against
      awayAgainst
      awayDrawn
      awayFor
      awayLost
      awayPlayed
      awayPoints
      awayPosition
      awayWon
      drawn
      for
      homeAgainst
      homeDrawn
      homeFor
      homeLost
      homePlayed
      homePoints
      homePosition
      homeWon
      lost
      played
      currentOutcome
      points
      position
      startDayPosition
      won
      createdAt
      updatedAt
      competition {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      season {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      stats {
        id
        totalYelCard
        wonTackle
        gamesPlayed
        totalRedCards
        fkFoulLost
        passingAccuracy
        totalPass
        fkFoulWon
        goalConversion
        totalTackle
        totalOffside
        goals
        totalScoringAtt
        ontargetScoringAtt
        goalAssists
        headedGoals
        hitWoodwork
        offsides
        ownGoalsConceded
        penaltiesConceded
        penaltiesTaken
        penaltyGoals
        setPiecesGoals
        starts
        substituteOff
        substituteOn
        shirtNumber
        createdAt
        updatedAt
      }
      team {
        id
        name
        country
        abbreviation
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOptaStats = /* GraphQL */ `
  mutation CreateOptaStats(
    $input: CreateOptaStatsInput!
    $condition: ModelOptaStatsConditionInput
  ) {
    createOptaStats(input: $input, condition: $condition) {
      id
      totalYelCard
      wonTackle
      gamesPlayed
      totalRedCards
      fkFoulLost
      passingAccuracy
      totalPass
      fkFoulWon
      goalConversion
      totalTackle
      totalOffside
      goals
      totalScoringAtt
      ontargetScoringAtt
      goalAssists
      headedGoals
      hitWoodwork
      offsides
      ownGoalsConceded
      penaltiesConceded
      penaltiesTaken
      penaltyGoals
      setPiecesGoals
      starts
      substituteOff
      substituteOn
      shirtNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateOptaStats = /* GraphQL */ `
  mutation UpdateOptaStats(
    $input: UpdateOptaStatsInput!
    $condition: ModelOptaStatsConditionInput
  ) {
    updateOptaStats(input: $input, condition: $condition) {
      id
      totalYelCard
      wonTackle
      gamesPlayed
      totalRedCards
      fkFoulLost
      passingAccuracy
      totalPass
      fkFoulWon
      goalConversion
      totalTackle
      totalOffside
      goals
      totalScoringAtt
      ontargetScoringAtt
      goalAssists
      headedGoals
      hitWoodwork
      offsides
      ownGoalsConceded
      penaltiesConceded
      penaltiesTaken
      penaltyGoals
      setPiecesGoals
      starts
      substituteOff
      substituteOn
      shirtNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptaStats = /* GraphQL */ `
  mutation DeleteOptaStats(
    $input: DeleteOptaStatsInput!
    $condition: ModelOptaStatsConditionInput
  ) {
    deleteOptaStats(input: $input, condition: $condition) {
      id
      totalYelCard
      wonTackle
      gamesPlayed
      totalRedCards
      fkFoulLost
      passingAccuracy
      totalPass
      fkFoulWon
      goalConversion
      totalTackle
      totalOffside
      goals
      totalScoringAtt
      ontargetScoringAtt
      goalAssists
      headedGoals
      hitWoodwork
      offsides
      ownGoalsConceded
      penaltiesConceded
      penaltiesTaken
      penaltyGoals
      setPiecesGoals
      starts
      substituteOff
      substituteOn
      shirtNumber
      createdAt
      updatedAt
    }
  }
`;
export const createOptaTeam = /* GraphQL */ `
  mutation CreateOptaTeam(
    $input: CreateOptaTeamInput!
    $condition: ModelOptaTeamConditionInput
  ) {
    createOptaTeam(input: $input, condition: $condition) {
      id
      name
      country
      abbreviation
      createdAt
      updatedAt
    }
  }
`;
export const updateOptaTeam = /* GraphQL */ `
  mutation UpdateOptaTeam(
    $input: UpdateOptaTeamInput!
    $condition: ModelOptaTeamConditionInput
  ) {
    updateOptaTeam(input: $input, condition: $condition) {
      id
      name
      country
      abbreviation
      createdAt
      updatedAt
    }
  }
`;
export const deleteOptaTeam = /* GraphQL */ `
  mutation DeleteOptaTeam(
    $input: DeleteOptaTeamInput!
    $condition: ModelOptaTeamConditionInput
  ) {
    deleteOptaTeam(input: $input, condition: $condition) {
      id
      name
      country
      abbreviation
      createdAt
      updatedAt
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      data
      leagueID
      seasonID
      matchID
      environment
      source
      optaLeague {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      optaMatch {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      optaSeason {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      srLeague {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      srSeason {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
      srMatch {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      data
      leagueID
      seasonID
      matchID
      environment
      source
      optaLeague {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      optaMatch {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      optaSeason {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      srLeague {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      srSeason {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
      srMatch {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      data
      leagueID
      seasonID
      matchID
      environment
      source
      optaLeague {
        id
        name
        code
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      optaMatch {
        id
        type
        period
        date
        city
        venue
        homeTeamID
        awayTeamID
        competitionID
        seasonID
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        season {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        homeTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
        awayTeam {
          id
          name
          country
          abbreviation
          createdAt
          updatedAt
        }
      }
      optaSeason {
        id
        name
        seasonID
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          code
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
      }
      srLeague {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      srSeason {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
      srMatch {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSrCategory = /* GraphQL */ `
  mutation CreateSrCategory(
    $input: CreateSRCategoryInput!
    $condition: ModelSRCategoryConditionInput
  ) {
    createSRCategory(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
    }
  }
`;
export const updateSrCategory = /* GraphQL */ `
  mutation UpdateSrCategory(
    $input: UpdateSRCategoryInput!
    $condition: ModelSRCategoryConditionInput
  ) {
    updateSRCategory(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteSrCategory = /* GraphQL */ `
  mutation DeleteSrCategory(
    $input: DeleteSRCategoryInput!
    $condition: ModelSRCategoryConditionInput
  ) {
    deleteSRCategory(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
    }
  }
`;
export const createSrCompetition = /* GraphQL */ `
  mutation CreateSrCompetition(
    $input: CreateSRCompetitionInput!
    $condition: ModelSRCompetitionConditionInput
  ) {
    createSRCompetition(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSrCompetition = /* GraphQL */ `
  mutation UpdateSrCompetition(
    $input: UpdateSRCompetitionInput!
    $condition: ModelSRCompetitionConditionInput
  ) {
    updateSRCompetition(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSrCompetition = /* GraphQL */ `
  mutation DeleteSrCompetition(
    $input: DeleteSRCompetitionInput!
    $condition: ModelSRCompetitionConditionInput
  ) {
    deleteSRCompetition(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSrCompetitor = /* GraphQL */ `
  mutation CreateSrCompetitor(
    $input: CreateSRCompetitorInput!
    $condition: ModelSRCompetitorConditionInput
  ) {
    createSRCompetitor(input: $input, condition: $condition) {
      id
      name
      country
      countryCode
      abbreviation
      gender
      createdAt
      updatedAt
      players {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSrCompetitor = /* GraphQL */ `
  mutation UpdateSrCompetitor(
    $input: UpdateSRCompetitorInput!
    $condition: ModelSRCompetitorConditionInput
  ) {
    updateSRCompetitor(input: $input, condition: $condition) {
      id
      name
      country
      countryCode
      abbreviation
      gender
      createdAt
      updatedAt
      players {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSrCompetitor = /* GraphQL */ `
  mutation DeleteSrCompetitor(
    $input: DeleteSRCompetitorInput!
    $condition: ModelSRCompetitorConditionInput
  ) {
    deleteSRCompetitor(input: $input, condition: $condition) {
      id
      name
      country
      countryCode
      abbreviation
      gender
      createdAt
      updatedAt
      players {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSrCompetitorPlayer = /* GraphQL */ `
  mutation CreateSrCompetitorPlayer(
    $input: CreateSRCompetitorPlayerInput!
    $condition: ModelSRCompetitorPlayerConditionInput
  ) {
    createSRCompetitorPlayer(input: $input, condition: $condition) {
      id
      competitorID
      playerID
      jerseyNumber
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const updateSrCompetitorPlayer = /* GraphQL */ `
  mutation UpdateSrCompetitorPlayer(
    $input: UpdateSRCompetitorPlayerInput!
    $condition: ModelSRCompetitorPlayerConditionInput
  ) {
    updateSRCompetitorPlayer(input: $input, condition: $condition) {
      id
      competitorID
      playerID
      jerseyNumber
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const deleteSrCompetitorPlayer = /* GraphQL */ `
  mutation DeleteSrCompetitorPlayer(
    $input: DeleteSRCompetitorPlayerInput!
    $condition: ModelSRCompetitorPlayerConditionInput
  ) {
    deleteSRCompetitorPlayer(input: $input, condition: $condition) {
      id
      competitorID
      playerID
      jerseyNumber
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const createSrPlayer = /* GraphQL */ `
  mutation CreateSrPlayer(
    $input: CreateSRPlayerInput!
    $condition: ModelSRPlayerConditionInput
  ) {
    createSRPlayer(input: $input, condition: $condition) {
      id
      name
      type
      DOB
      nationality
      countryCode
      height
      weight
      placeOfBirth
      gender
      createdAt
      updatedAt
      competitors {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSrPlayer = /* GraphQL */ `
  mutation UpdateSrPlayer(
    $input: UpdateSRPlayerInput!
    $condition: ModelSRPlayerConditionInput
  ) {
    updateSRPlayer(input: $input, condition: $condition) {
      id
      name
      type
      DOB
      nationality
      countryCode
      height
      weight
      placeOfBirth
      gender
      createdAt
      updatedAt
      competitors {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSrPlayer = /* GraphQL */ `
  mutation DeleteSrPlayer(
    $input: DeleteSRPlayerInput!
    $condition: ModelSRPlayerConditionInput
  ) {
    deleteSRPlayer(input: $input, condition: $condition) {
      id
      name
      type
      DOB
      nationality
      countryCode
      height
      weight
      placeOfBirth
      gender
      createdAt
      updatedAt
      competitors {
        items {
          id
          competitorID
          playerID
          jerseyNumber
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSrPlayerStatistics = /* GraphQL */ `
  mutation CreateSrPlayerStatistics(
    $input: CreateSRPlayerStatisticsInput!
    $condition: ModelSRPlayerStatisticsConditionInput
  ) {
    createSRPlayerStatistics(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      playerID
      assists
      cardsGiven
      cornerKicks
      goalsByHead
      goalsByPenalty
      goalsScored
      matchesPlayed
      minutesPlayed
      offsides
      ownGoals
      redCards
      shotsBlocked
      shotsOffTarget
      shotsOnTarget
      substitutedIn
      substitutedOut
      yellowCards
      createdAt
      updatedAt
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const updateSrPlayerStatistics = /* GraphQL */ `
  mutation UpdateSrPlayerStatistics(
    $input: UpdateSRPlayerStatisticsInput!
    $condition: ModelSRPlayerStatisticsConditionInput
  ) {
    updateSRPlayerStatistics(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      playerID
      assists
      cardsGiven
      cornerKicks
      goalsByHead
      goalsByPenalty
      goalsScored
      matchesPlayed
      minutesPlayed
      offsides
      ownGoals
      redCards
      shotsBlocked
      shotsOffTarget
      shotsOnTarget
      substitutedIn
      substitutedOut
      yellowCards
      createdAt
      updatedAt
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const deleteSrPlayerStatistics = /* GraphQL */ `
  mutation DeleteSrPlayerStatistics(
    $input: DeleteSRPlayerStatisticsInput!
    $condition: ModelSRPlayerStatisticsConditionInput
  ) {
    deleteSRPlayerStatistics(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      playerID
      assists
      cardsGiven
      cornerKicks
      goalsByHead
      goalsByPenalty
      goalsScored
      matchesPlayed
      minutesPlayed
      offsides
      ownGoals
      redCards
      shotsBlocked
      shotsOffTarget
      shotsOnTarget
      substitutedIn
      substitutedOut
      yellowCards
      createdAt
      updatedAt
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
    }
  }
`;
export const createSrPushEvent = /* GraphQL */ `
  mutation CreateSrPushEvent(
    $input: CreateSRPushEventInput!
    $condition: ModelSRPushEventConditionInput
  ) {
    createSRPushEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      sportEventID
      id
      type
      eventTime
      matchClock
      matchTime
      team
      competitor
      outcome
      injuryTimeAnnounced
      periodName
      homeScore
      awayScore
      players
      player
      createdAt
      updatedAt
    }
  }
`;
export const updateSrPushEvent = /* GraphQL */ `
  mutation UpdateSrPushEvent(
    $input: UpdateSRPushEventInput!
    $condition: ModelSRPushEventConditionInput
  ) {
    updateSRPushEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      sportEventID
      id
      type
      eventTime
      matchClock
      matchTime
      team
      competitor
      outcome
      injuryTimeAnnounced
      periodName
      homeScore
      awayScore
      players
      player
      createdAt
      updatedAt
    }
  }
`;
export const deleteSrPushEvent = /* GraphQL */ `
  mutation DeleteSrPushEvent(
    $input: DeleteSRPushEventInput!
    $condition: ModelSRPushEventConditionInput
  ) {
    deleteSRPushEvent(input: $input, condition: $condition) {
      competitionID
      seasonID
      sportEventID
      id
      type
      eventTime
      matchClock
      matchTime
      team
      competitor
      outcome
      injuryTimeAnnounced
      periodName
      homeScore
      awayScore
      players
      player
      createdAt
      updatedAt
    }
  }
`;
export const createSrSeason = /* GraphQL */ `
  mutation CreateSrSeason(
    $input: CreateSRSeasonInput!
    $condition: ModelSRSeasonConditionInput
  ) {
    createSRSeason(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      year
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      sportEvents {
        items {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSrSeason = /* GraphQL */ `
  mutation UpdateSrSeason(
    $input: UpdateSRSeasonInput!
    $condition: ModelSRSeasonConditionInput
  ) {
    updateSRSeason(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      year
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      sportEvents {
        items {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSrSeason = /* GraphQL */ `
  mutation DeleteSrSeason(
    $input: DeleteSRSeasonInput!
    $condition: ModelSRSeasonConditionInput
  ) {
    deleteSRSeason(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      year
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      sportEvents {
        items {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          awayCompetitorID
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSrSeasonStanding = /* GraphQL */ `
  mutation CreateSrSeasonStanding(
    $input: CreateSRSeasonStandingInput!
    $condition: ModelSRSeasonStandingConditionInput
  ) {
    createSRSeasonStanding(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      type
      rank
      played
      win
      loss
      draw
      goalDiff
      points
      change
      currentOutcome
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const updateSrSeasonStanding = /* GraphQL */ `
  mutation UpdateSrSeasonStanding(
    $input: UpdateSRSeasonStandingInput!
    $condition: ModelSRSeasonStandingConditionInput
  ) {
    updateSRSeasonStanding(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      type
      rank
      played
      win
      loss
      draw
      goalDiff
      points
      change
      currentOutcome
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const deleteSrSeasonStanding = /* GraphQL */ `
  mutation DeleteSrSeasonStanding(
    $input: DeleteSRSeasonStandingInput!
    $condition: ModelSRSeasonStandingConditionInput
  ) {
    deleteSRSeasonStanding(input: $input, condition: $condition) {
      id
      seasonID
      competitorID
      type
      rank
      played
      win
      loss
      draw
      goalDiff
      points
      change
      currentOutcome
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const createSrSportEvent = /* GraphQL */ `
  mutation CreateSrSportEvent(
    $input: CreateSRSportEventInput!
    $condition: ModelSRSportEventConditionInput
  ) {
    createSRSportEvent(input: $input, condition: $condition) {
      id
      startTime
      startTimeConfirmed
      homeCompetitorID
      awayCompetitorID
      seasonID
      categoryID
      status
      matchStatus
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      category {
        id
        name
        countryCode
        createdAt
        updatedAt
      }
      homeCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      awayCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const updateSrSportEvent = /* GraphQL */ `
  mutation UpdateSrSportEvent(
    $input: UpdateSRSportEventInput!
    $condition: ModelSRSportEventConditionInput
  ) {
    updateSRSportEvent(input: $input, condition: $condition) {
      id
      startTime
      startTimeConfirmed
      homeCompetitorID
      awayCompetitorID
      seasonID
      categoryID
      status
      matchStatus
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      category {
        id
        name
        countryCode
        createdAt
        updatedAt
      }
      homeCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      awayCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const deleteSrSportEvent = /* GraphQL */ `
  mutation DeleteSrSportEvent(
    $input: DeleteSRSportEventInput!
    $condition: ModelSRSportEventConditionInput
  ) {
    deleteSRSportEvent(input: $input, condition: $condition) {
      id
      startTime
      startTimeConfirmed
      homeCompetitorID
      awayCompetitorID
      seasonID
      categoryID
      status
      matchStatus
      homeScore
      awayScore
      winnerID
      createdAt
      updatedAt
      category {
        id
        name
        countryCode
        createdAt
        updatedAt
      }
      homeCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      awayCompetitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      season {
        id
        name
        startDate
        endDate
        year
        competitionID
        createdAt
        updatedAt
        competition {
          id
          name
          createdAt
          updatedAt
        }
        sportEvents {
          nextToken
        }
      }
    }
  }
`;
export const createSrSportEventLineup = /* GraphQL */ `
  mutation CreateSrSportEventLineup(
    $input: CreateSRSportEventLineupInput!
    $condition: ModelSRSportEventLineupConditionInput
  ) {
    createSRSportEventLineup(input: $input, condition: $condition) {
      id
      sportEventID
      competitorID
      playerID
      jerseyNumber
      starter
      played
      order
      position
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
      sportEvent {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSrSportEventLineup = /* GraphQL */ `
  mutation UpdateSrSportEventLineup(
    $input: UpdateSRSportEventLineupInput!
    $condition: ModelSRSportEventLineupConditionInput
  ) {
    updateSRSportEventLineup(input: $input, condition: $condition) {
      id
      sportEventID
      competitorID
      playerID
      jerseyNumber
      starter
      played
      order
      position
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
      sportEvent {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSrSportEventLineup = /* GraphQL */ `
  mutation DeleteSrSportEventLineup(
    $input: DeleteSRSportEventLineupInput!
    $condition: ModelSRSportEventLineupConditionInput
  ) {
    deleteSRSportEventLineup(input: $input, condition: $condition) {
      id
      sportEventID
      competitorID
      playerID
      jerseyNumber
      starter
      played
      order
      position
      createdAt
      updatedAt
      competitor {
        id
        name
        country
        countryCode
        abbreviation
        gender
        createdAt
        updatedAt
        players {
          nextToken
        }
      }
      player {
        id
        name
        type
        DOB
        nationality
        countryCode
        height
        weight
        placeOfBirth
        gender
        createdAt
        updatedAt
        competitors {
          nextToken
        }
      }
      sportEvent {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
