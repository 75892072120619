import React from 'react';

import useTabsStore from 'store/useTabs';

const Item = ({ label, onClick, selected }) => (
  <li className="px-2 inline" onClick={onClick}>
    <button
      className={`hover:text-gray-800 focus:outline-none px-2 py-4 mt-1 -mb-1 ${
        selected
          ? 'font-normal text-black border-b border-black'
          : 'text-gray-600'
      }`}
    >
      {label}
    </button>
  </li>
);

const TabLinks = () => {
  const list = useTabsStore(state => state.list);
  const selected = useTabsStore(state => state.selected);
  const setSelected = useTabsStore(state => state.setSelected);

  return (
    <ul className="-ml-2">
      {list.map((o, i) => (
        <Item
          key={o}
          label={o}
          selected={selected === i}
          onClick={() => setSelected(i)}
        />
      ))}
    </ul>
  );
};

export default TabLinks;
