import { useState } from 'react';

import { deleteTemplate } from 'graphql/mutations';
import useTemplatesStore from 'store/useTemplates';
import query from 'utils/query';

function useTemplateRemove() {
  const getTemplates = useTemplatesStore(state => state.getList);
  const setTemplates = useTemplatesStore(state => state.setList);


  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState();

  async function remove(id) {
    setIsRemoving(true);
    try {
      const templates = getTemplates();
      const { data } = await query(deleteTemplate, { input: { id } });
      setTemplates(templates.filter(o => o && o.id !== data.deleteTemplate.id));
    } catch (error) {
      setError(error);
    }
    // Only update if we weren't deleted, otherwise React complains
    // that we are a resource leak.
    if(error) setIsRemoving(false);
  }

  return [remove, isRemoving, error];
}

export default useTemplateRemove;
