import * as dayjs from 'dayjs';

export const transformLeague = ({ id, name, seasons }) => {
  const country = seasons?.items?.[0]?.sportEvents?.items?.[0]?.category?.name;
  return {
    name: country ? `${country} ${name}` : name,
    country,
    id
  };
};
/**
 * Transform the match object into the data we need and use. This function
 * normalizes the differences between the Opta and SR schemas.
 */
export const transformMatch = ({ id, season, ...data }) => {
  // TODO: Setting home and away to Unknown for missing keys. This needs to be
  // addressed in the near future. This issue can be reproduced by selecting
  // Sportradar > Hungary Magyar Kupa and looking at games on Tue Sep 19 (2020).
  const away = (data.awayCompetitor || data.awayTeam || {}).name || 'Unknown';
  const home = (data.homeCompetitor || data.homeTeam || {}).name || 'Unknown';
  const date = data.date || data.startTime;
  const day = dayjs(date).format('ddd MMM DD');
  const time = dayjs(date).format('HH:mm');
  const sortValue = +date.replace(/[^0-9]/g, '');

  const timezone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];

  const versus = `${home} vs ${away}`;
  const daytime = `${day}, ${time} ${timezone}`;

  return {
    away,
    date,
    day,
    daytime,
    home,
    id,
    season,
    sortValue,
    time,
    timezone,
    versus
  };
};

/**
 * Transform a single template. This function normalizes the differences between
 * the Opta and SR schemas.
 */
export const transformTemplate = o => {
  const league = transformLeague(o.optaLeague || o.srLeague);
  const match = {
    ...transformMatch(o.optaMatch || o.srMatch),
    season: o.optaSeason || o.srSeason
  };
  const data = typeof o.data === 'string' ? JSON.parse(o.data) : o.data;
  return {
    ...o,
    data,
    league,
    match
  };
};

/**
 * Transform a list of template objects. This function normalizes the
 * differences between the Opta and SR schemas.
 *
 * TODO: Apply proper sorting via VTL sortTool (which is currently unsupported
 * by AppSync) or by adding an index as described here:
 * https://medium.com/@mrsze/sorting-data-in-awsamplify-and-dynamodb-with-key-directive-6f04f0cd427f)
 */
export const transformTemplateList = items =>
  items
    .map(transformTemplate)
    .filter(x => !!x)
    .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));

export default transformTemplateList;
