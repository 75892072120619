import * as React from 'react';
import PropTypes from 'prop-types';

function Icon({ d, size, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <path className="fill-current" d={d} />
    </svg>
  );
}

Icon.defaultProps = {
  size: 24
};

Icon.propTypes = {
  d: PropTypes.string.isRequired,
  size: PropTypes.number
};

export default Icon;
