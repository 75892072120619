import create from 'zustand';

const initialState = {
  user: null
};

export const [useUserStore] = create(set => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setUser: newUser =>
    set(() => {
      if (!newUser) return;
      return {
        user: {
          id: newUser.IdentityId,
          credentials: newUser.Credentials
        }
      };
    })
}));

export default useUserStore;
