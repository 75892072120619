import React, { useCallback } from 'react';

import useNameStore from 'store/useName';

const Name = () => {
  const value = useNameStore(state => state.value);
  const setValue = useNameStore(state => state.setValue);

  const handleChange = useCallback(e => setValue(e.target.value), [setValue]);

  return (
    <input
      className="form-input w-full"
      onChange={handleChange}
      placeholder="Set the name of this template"
      type="text"
      value={value}
    />
  );
};

export default Name;
