import React from 'react';

const Label = ({ children, variant = 'label' }) =>
  React.createElement(variant, { className: 'flex mt-4' }, children);

export const Block = props => (
  <Label variant={props.variant}>
    <div className="text-gray-600 w-2/12 pt-2">{props.text}</div>
    <div className="w-10/12">{props.children}</div>
  </Label>
);

export default Block;
