import React from 'react';

const Card = props => (
  <div className="w-full max-w-4xl mx-auto rounded overflow-hidden bg-white border border-gray-400">
    <div className="p-4 bg-gray-200">{props.header}</div>
    <div className="font-bold text-xl mb-3">{props.title}</div>
    <div>{props.body}</div>
    <div className="px-6 py-4">{props.footer}</div>
  </div>
);

export default Card;
