import { useState } from 'react';

import AWS from 'aws-sdk';
import Storage from '@aws-amplify/storage';

import { AWS_STORAGE_FOLDER, AWS_STORAGE_ROOT } from 'utils/constants';

function useStorage() {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  /**
   * Store file in public folder under user's id and footballstats folder.
   */
  async function upload(files) {
    setIsUploading(true);
    try {
      const folder = `${AWS.config.credentials.data.IdentityId}/${AWS_STORAGE_FOLDER}/`;
      const file = files[0];
      const path = folder + file.name;
      const result = await Storage.put(path, file, { contentType: file.type });
      setSuccess(AWS_STORAGE_ROOT + result.key);
    } catch (error) {
      setError(error);
    }
    setIsUploading(false);
  }

  return { error, isUploading, success, upload };
}

export default useStorage;
