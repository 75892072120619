import create from 'zustand';

const initialState = {
  message: undefined
};

export const [useErrorStore] = create(set => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setMessage: newMessage => set(() => ({ message: newMessage }))
}));

export default useErrorStore;
