import { useState } from 'react';

import { createTemplate } from 'graphql/mutations';

import useTemplatesStore from 'store/useTemplates';
import { queryTransform } from 'utils/query';
import { transformTemplateList } from 'utils/transforms';

function useTemplateCreate() {
  const templates = useTemplatesStore(state => state.list);
  const setTemplates = useTemplatesStore(state => state.setList);

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState();

  async function create(template) {
    setIsCreating(true);
    try {
      const newTemplates = await queryTransform(
        [createTemplate, { input: template }],
        ({ data }) => transformTemplateList([data.createTemplate, ...templates])
      );
      setTemplates(newTemplates);
    } catch (error) {
      console.error('Template create error', error);
      setError("We're having trouble creating your template");
    }
    setIsCreating(false);
  }

  return [create, isCreating, error];
}

export default useTemplateCreate;
