import create from 'zustand';

import { DATA_SOURCES } from 'utils/constants';

// FIXME Phase 2: import { apiLeagueStore, options } from 'store/useLeague';
import { apiMatchStore } from 'store/useMatch';

const initialState = {
  list: [...DATA_SOURCES],
  // FIXME Phase 2: selected: undefined
  selected: DATA_SOURCES[0]
};

export const [useSource, apiSource] = create((set, get) => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setSelected: newSourceId =>
    set(() => {
      // Update the league dropdown list when the source selection changes.
      // FIXME Phase 2: apiLeagueStore.getState().reset();
      // FIXME Phase 2: apiLeagueStore.getState().fetch(options, newSourceId);

      // Reset match dropdown since source has changed.
      apiMatchStore.getState().reset();

      return { selected: get().list.find(o => o.id === newSourceId) };
    })
}));

export default useSource;
