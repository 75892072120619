import React, { useCallback } from 'react';

import CancelButton from 'components/Button/Cancel';

function Modal({ body, cancel, proceed, title, isOpen, onCancel, onProceed }) {
  const handleClickCancel = useCallback(() => onCancel(), [onCancel]);
  const handleClickProceed = useCallback(() => onProceed(), [onProceed]);

  return (
    <div
      className={`z-max flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 bg-opacity-75 transition ease-in-out duration-200 ${
        isOpen ? 'opacity-1' : 'opacity-0'
      }`}
    >
      <div className="bg-white rounded-lg w-96">
        <div className="flex flex-col items-start p-4">
          <div className="flex items-center w-full">
            <div className="text-gray-800 font-medium text-lg">{title}</div>
            <div
              className="ml-auto hover:bg-gray-200 p-1 rounded"
              onClick={handleClickCancel}
            >
              <svg
                className="fill-current text-gray-600 w-6 h-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <div className="py-6 text-gray-800">{body}</div>
          <div className="ml-auto mt-2">
            <CancelButton
              className="mr-2"
              label={cancel}
              onClick={handleClickCancel}
            />
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-normal py-2 px-4 rounded focus:outline-none"
              onClick={handleClickProceed}
            >
              {proceed}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  cancel: 'Cancel',
  isOpen: false,
  proceed: 'Continue',
  title: 'Confirmation'
};

const MemoModal = React.memo(Modal);
export default MemoModal;
