import React, { useCallback } from 'react';

import useFeaturesStore from 'store/useFeatures';
import group from 'utils/group';

import Select from './Select';

const format = o =>
  Object.entries(o).map(([k, v]) => ({ label: k, options: v }));

const Features = () => {
  const enabled = useFeaturesStore(state => state.enabled);
  const list = useFeaturesStore(state => state.list);
  const setEnabled = useFeaturesStore(state => state.setEnabled);

  const handleChange = useCallback(
    items => setEnabled(items && items.map(o => o.value)),
    [setEnabled]
  );

  const grouped = format(group(list, 'category'));

  return (
    <Select
      closeMenuOnSelect={false}
      value={enabled}
      onChange={handleChange}
      options={grouped}
      placeholder="Select features to enable..."
      isMulti
    />
  );
};

export default Features;
