import React from 'react';
import Icon from './Icon';

const MemoArrowLeft = React.memo(props => (
  <Icon
    d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"
    {...props}
  />
));

export default MemoArrowLeft;
