import React, { useEffect, useState } from 'react';

import useSourceStore from 'store/useSource';
import useErrorStore from 'store/useError';
import useFeaturesStore from 'store/useFeatures';
import useLeagueStore from 'store/useLeague';
import useMatchStore from 'store/useMatch';
import useNameStore from 'store/useName';
import resetTemplateId from 'store/useTemplateId';

import Card from 'components/Card';
import Warning from 'components/Alert/Warning';

import Block from './Block';
// FIXME Phase 2: import DataSource from './Source';
import Features from './Features';
// FIXME Phase 2: import League from './League';
import Match from './Match';
import Name from './Name';
import Sponsor from './Sponsor';

const EditWarning = () => (
  <Warning text="You're editing an existing template." />
);

const Form = ({ onChange, onError }) => {
  const errorMessage = useErrorStore(state => state.message);
  const features = useFeaturesStore(state => state.enabled);
  const league = useLeagueStore(state => state.selected);
  const match = useMatchStore(state => state.selected);
  const name = useNameStore(state => state.value);
  const source = useSourceStore(state => state.selected);
  const templateId = resetTemplateId(state => state.value);

  const [canSubmit, setCanSubmit] = useState(false);

  console.debug('form update', {
    name,
    league,
    match,
    features,
    source
  });

  useEffect(() => errorMessage && onError(errorMessage), [
    errorMessage,
    onError
  ]);

  useEffect(
    () =>
      setCanSubmit(!!(name && league && match && source && features.length)),
    [name, league, match, source, features]
  );

  useEffect(() => onChange(canSubmit), [canSubmit, onChange]);

  return (
    <div>
      <Card
        header={
          <div className="h-16 -mt-2 -mb-2 flex justify-between items-center flex-wrap">
            <div className="w-1/2 truncate">
              {name || <span className="text-gray-500">No template name</span>}
            </div>
            <div className="w-1/2">
              <div className="flex flex-col items-end">
                {match ? (
                  <>
                    <span className="text-xs text-white bg-green-600 px-2 py-1 rounded">
                      {match.daytime}
                    </span>
                    <span className="text-gray-700 mt-1">{match.versus}</span>
                  </>
                ) : (
                  <span className="text-gray-500">No match selected</span>
                )}
              </div>
            </div>
          </div>
        }
        body={
          <div className="flex flex-col px-6 py-4">
            {templateId && <EditWarning />}

            <Block text="Name">
              <Name />
            </Block>

            {/* FIXME Phase 2:
            <Block text="Source" variant="div">
              <DataSource />
            </Block>
            <Block text="League">
              <League />
            </Block>
            */}
            <Block text="Match">
              <Match />
            </Block>

            <Block text="Features" variant="div">
              <Features />
            </Block>

            <Block text="Sponsors" variant="div">
              <Sponsor />
            </Block>
          </div>
        }
      />
    </div>
  );
};

export default Form;
