// Custom queries needed for the admin app

export const listSrCompetitionsIncludeCategory = /* GraphQL */ `
  query ListSrCompetitions(
    $filter: ModelSRCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        seasons(limit: 1) {
          items {
            sportEvents(limit: 1) {
              items {
                category {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getSRSeasonIncludeCompetitors = /* GraphQL */ `
  query GetSrSeason($id: ID!) {
    getSRSeason(id: $id) {
      id
      name
      startDate
      endDate
      year
      competitionID
      createdAt
      updatedAt
      competition {
        id
        name
        createdAt
        updatedAt
        seasons {
          nextToken
        }
      }
      sportEvents {
        items {
          id
          startTime
          startTimeConfirmed
          homeCompetitorID
          homeCompetitor {
            name
          }
          awayCompetitorID
          awayCompetitor {
            name
          }
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const templatesByEnvironment = /* GraphQL */ `
  query TemplatesByEnvironment(
    $environment: Environment
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByEnvironment(
      environment: $environment
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        data
        leagueID
        seasonID
        matchID
        environment
        source
        optaLeague {
          id
          name
          code
          createdAt
          updatedAt
        }
        optaMatch {
          id
          type
          period
          date
          city
          venue
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          competitionID
          seasonID
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
        optaSeason {
          id
          name
          seasonID
          competitionID
          createdAt
          updatedAt
        }
        srLeague {
          id
          name
          createdAt
          updatedAt
          seasons(limit: 1) {
            items {
              sportEvents(limit: 1) {
                items {
                  category {
                    name
                  }
                }
              }
            }
          }
        }
        srSeason {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        srMatch {
          id
          startTime
          startTimeConfirmed
          homeCompetitor {
            name
          }
          awayCompetitor {
            name
          }
          seasonID
          categoryID
          status
          matchStatus
          homeScore
          awayScore
          winnerID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const optaMatchesByCompetition = /* GraphQL */ `
  query MatchesByCompetition(
    $date: ModelStringKeyConditionInput
    $filter: ModelOptaMatchFilterInput
    $limit: Int
    $nextToken: String
    $competitionID: ID
    $sortDirection: ModelSortDirection
  ) {
    matches: matchesByCompetition(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      competitionID: $competitionID
      sortDirection: $sortDirection
    ) {
      items {
        id
        ...mainFields
        homeTeam {
          ...competitorFields
        }
        awayTeam {
          ...competitorFields
        }
        competition {
          name
        }
        season {
          id
          name
          seasonID
          competitionID
        }
      }
      nextToken
    }
  }

  fragment mainFields on OptaMatch {
    date
    homeTeamID
    awayTeamID
    seasonID
    winnerID
  }

  fragment competitorFields on OptaTeam {
    id
    name
    abbreviation
  }
`;

export const listSrSportEvents = /* GraphQL */ `
  query ListSrSportEvents(
    $filter: ModelSRSportEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSRSportEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startTime
        startTimeConfirmed
        homeCompetitorID
        awayCompetitorID
        seasonID
        categoryID
        status
        matchStatus
        homeScore
        awayScore
        winnerID
        createdAt
        updatedAt
        category {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        homeCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        awayCompetitor {
          id
          name
          country
          countryCode
          abbreviation
          gender
          createdAt
          updatedAt
        }
        season {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const getSrCompetition = /* GraphQL */ `
  query GetSrCompetition($id: ID!) {
    getSRCompetition(id: $id) {
      id
      name
      createdAt
      updatedAt
      seasons {
        items {
          id
          name
          startDate
          endDate
          year
          competitionID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
