import { useState } from 'react';

import { templatesByEnvironment } from 'graphql/custom/queries';

import useTemplatesStore from 'store/useTemplates';

import { ENV, TEMPLATE_LIMIT } from 'utils/constants';
import { queryTransform } from 'utils/query';
import { transformTemplateList } from 'utils/transforms';

function useTemplateLoad() {
  const setTemplates = useTemplatesStore(state => state.setList);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  async function load(user) {
    setIsLoading(true);
    try {
      const newTemplates = await queryTransform(
        [
          templatesByEnvironment,
          {
            environment: ENV,
            owner: { eq: user },
            limit: TEMPLATE_LIMIT
          }
        ],
        ({ data }) => transformTemplateList(data.templatesByEnvironment.items)
      );
      setTemplates(newTemplates);
    } catch (error) {
      console.error('Error fetching templates.', error);
      setError(error);
    }
    setIsLoading(false);
  }

  return [load, isLoading, error];
}

export default useTemplateLoad;
