import create from 'zustand';

const initialState = {
  value: ''
};

export const [useNameStore] = create(set => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setValue: newValue => set(() => ({ value: newValue }))
}));

export default useNameStore;
