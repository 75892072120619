import API, { graphqlOperation } from '@aws-amplify/api';

import { AWS_AUTH_TYPE } from 'utils/constants';

export const query = async (method, config) =>
  await API.graphql(graphqlOperation(method, config), AWS_AUTH_TYPE);

export const queryTransform = async (input, callback) =>
  callback(await query(...input));

export default query;
