import create from 'zustand';

const initialState = {
  list: []
};

export const [useTemplatesStore] = create((set, get) => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setList: newList => set(() => ({ list: newList || [] })),
  getList: () => get().list
}));

export default useTemplatesStore;
