const log = config => (set, get, api) =>
  config(
    args => {
      console.debug('old state', get());
      set(args);
      console.debug('new state', get());
    },
    get,
    api
  );

export default log;
