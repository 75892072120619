import preval from 'preval.macro';

const version = preval`
const fs = require("fs");
let name = '';
try {
  const data = fs.readFileSync("package.json").toString();  
  const pkg = JSON.parse(data);
  name = pkg.name + '@' + pkg.version;
} catch(e) {
  console.log(e);
}
module.exports = name`;
export default version