import create from 'zustand';

import { TABS } from 'utils/constants';

const initialState = {
  list: [...TABS],
  selected: 0
};

export const [useTabsStore] = create(set => ({
  ...initialState,
  reset: () => set(() => ({ ...initialState })),
  setSelected: newNumber => set(() => ({ selected: newNumber }))
}));

export default useTabsStore;
