import React from 'react';
import PropTypes from 'prop-types';

function Button({ className, label, ...props }) {
  return (
    <button
      className={`font-normal py-2 px-3 text-sm text-white rounded focus:outline-none ${className}`}
      {...props}
    >
      {label}
    </button>
  );
}

Button.defaultProps = {
  className: ''
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default React.memo(Button);
